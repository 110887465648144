import { Component, HostListener, OnInit, ViewEncapsulation, ElementRef, ViewChild} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';
import { HomeService } from '../_services/home.service';
import Swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TokenStorageService } from '../_services/token-storage.service';
import * as AOS from 'aos';
import { EmbedVideoService } from 'ngx-embed-video';
import { filter } from 'rxjs/operators';
import { ProfileService } from '../_services/profile.service';
import { AuthService } from '../_services/auth.service';
import { environment } from 'src/environments/environment';
import { HeaderSearchService } from '../_services/header-search.service';
import { LoadingService } from '../_services/loading.service';
import { AddPropertyComponent } from '../add-property/add-property.component';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
    @ViewChild('searchkey') searchInput: ElementRef;
    public loading = true;
    artslist: any = [];
    artslist1: any = [];
    artslist2: any = [];
    artslist3: any = [];
    artslist4: any = [];
    listings: any[] = [];
    page: number = 1;
    limit: number = 10;
    sortactivetab = 'trending';
    mediums: any = [];
    userinfo: any = [];
    userPortfolio: any = [];
    customizeBackgroundContents: any = [];
    categories: any = [];
    searchkey: any = [];
    checkedcat: any = [];
    checkedmedium: any = [];
    count = 0;
    tableSize = 28;
    dropdownSettings: IDropdownSettings = {};
    dropdownSettingsmedium: IDropdownSettings = {};
    selectedCategories = [] as any;
    selectedCategoryIds = [] as any;
    selectedMediums = [] as any;
    selectedMediumIds = [] as any;
    artParama: any = { "artWorkCategories": [], "limit": 28, "mediumIds": [], "offset": 0, "searchString": "", "sortCriteria": "trending" };
    btnStyle = 'tablinks ';
    isLoggedIn = false;
    defaultThumbnail = '../assets/images/video.png';
    defaultThumbnailAudio = '../assets/images/audio.jpeg';
    maxCategoryMessage: boolean = false;
    searchResultMessage: boolean = false;
    maxMediumMessage: boolean = false;
    usertoken: any = [];
	searchValue:any='';
    socialOrigin: any;
    useremail: any;
	payload: any;
	searchData: string = '';
    currentIndex: number = 0;
    currentIndex1: number = 0;
    slides1 = [
       'https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/gchaturthiweb.png',
        'https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/banner_interiordesigners_2024.webp',
        'https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/banner_webflight_2024.webp',
        'https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/banner_hotels_2024.webp',
        'https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/banner_cctv_2024.webp'
      ];
      currentIndex2: number = 0;
      slides2 = [
        'https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/gchaturthiweb.png',
         'https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/banner_interiordesigners_2024.webp',
         'https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/banner_webflight_2024.webp',
         'https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/banner_hotels_2024.webp',
         'https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/banner_cctv_2024.webp'
       ];
    //   slides: string[] = [];
    slideConfig = {
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "nextArrow": "",
        "prevArrow": "",
        "dots": false,
        "infinite": true
    };
    timeoutId?: number;
    constructor(
        private homeService: HomeService,
        private tokenStorage: TokenStorageService,
        private profileService: ProfileService,
        private route: ActivatedRoute,
        private authService: AuthService,
        private embedService: EmbedVideoService,
        private router: Router,
		private searchService: HeaderSearchService,
        private loadingService: LoadingService,
    ) {}

    ngOnInit(): void {
        this.initMethod();
        this.loadListings();
		this.searchService.searchData$.subscribe(data => {
			this.searchData = data;
			this.performSearch(this.searchData);
		  });
        this.router.events.pipe(
            filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this.initMethod();
        });

        if (this.slides1.length === 0) {
            console.warn('No slides available.');
          }
          if (this.slides2.length === 0) {
            console.warn('No slides available.');
          }

          this.resetTimer1();
          this.resetTimer2();

        }
        

	performSearch(query: string) {
		// Implement your search logic here
        this.loadingService.show();
		this.searchValue=query;
		console.log('Search Query:', query);
		let formattedPayload;
		if (this.payload && this.payload.filterName && this.payload.filterValue) {
		formattedPayload = this.formatFilters(this.payload.filterName, this.payload.filterValue);
		} else {
		console.error('Payload is not properly initialized:', this.payload);
		formattedPayload = {}; 
		}

		let filters:any = {
			filterConditions: Object.keys(formattedPayload).length === 0 ? [] : [formattedPayload],
			sortConditions:[],
		  };
		  filters['searchValue']=this.searchValue;
		  filters['page'] = 0;
		  filters['size'] = 50;
		  console.log(filters);
		  this.homeService.Lead(filters).subscribe(
			(response: any) => {
				if (response && response.payload && response.payload.items) {
                    this.loadingService.hide();
					this.listings = response.payload.items;
                    if (this.listings.length===0) {
                        // this.router.navigate(['/notfound']);
                      }
					console.log('Listings:', this.listings,length);
				} else {
					console.error('Unexpected data format:', response);
					this.listings = [];
                  
				}
			},
			error => {
                this.loadingService.hide();
				console.error('Error loading listings:', error);
				this.listings = [];
			}
		);
	  }
      
	onPayloadReceived(payload: any) {
		this.payload = payload;
		console.log(payload);
        this.loadingService.show();

        let formattedPayload = this.formatFilters(this.payload.filterName, this.payload.filterValue);
        console.log(formattedPayload);
    
        const askingFilterIndex = formattedPayload.findIndex(item => item.filterName === "asking");
        
        if (askingFilterIndex !== -1) {
            const askingFilter = formattedPayload[askingFilterIndex];
    
            formattedPayload.splice(askingFilterIndex, 1);
    
            const [minValue, maxValue] = askingFilter.filterValue.split(" - ").map(value => value.trim());
    
            formattedPayload.push(
                {
                    filterName: "fromAsking",
                    filterValue: minValue
                },
                {
                    filterName: "toAsking",
                    filterValue: maxValue
                }
            );
        }
    
	
		if(this.payload.filterValue==""){
			this.loadListings();
		}
		else{
			let filters:any = {
				filterConditions: formattedPayload,
				sortConditions:[],
			  };
			  filters['searchValue']=this.searchValue;
			  filters['page'] = 0;
			  filters['size'] = 50;
			  console.log(filters);
			  this.homeService.Lead(filters).subscribe(
				(response: any) => {
					if (response && response.payload && response.payload.items) {
                this.loadingService.hide();
                if (this.listings.length===0) {
                    // this.router.navigate(['/notfound']);
                  }
						this.listings = response.payload.items;
						
						console.log('Listings:', this.listings);
					} else {
						console.error('Unexpected data format:', response);
						this.listings = [];
					}
				},
				error => {
                this.loadingService.hide();

					console.error('Error loading listings:', error);
					this.listings = [];
				}
			);
		}
		  
		// this.fetchListings(payload);
	  }
      formatFilters(filterName, filterValue) {
        
        if (!Array.isArray(filterName) || !Array.isArray(filterValue)) {
            console.error('filterName or filterValue is not an array or is undefined');
            return [];
        }
    
        const resultMap = {};
    
        // Map filterValues to their corresponding filterNames
        filterValue.forEach((value, index) => {
            const name = filterName[index] || filterName[filterName.length - 1];
    
            if (!resultMap[name]) { 
                resultMap[name] = value;
            } else {
                resultMap[name] += `, ${value}`;
            }
        });
    
        // Convert resultMap to resultArray
        const resultArray = Object.keys(resultMap).map(key => ({
            filterName: key,
            filterValue: resultMap[key]
        }));
    
        console.log(resultArray);
        return resultArray;
    }
    
    
    
    loadListings() {
        let filters: any = {
          filterConditions: [],
          sortConditions: [],
          searchValue: "",
          page: 0,
          size: 100
        };
    
        this.homeService.Lead(filters).subscribe(
          (response: any) => {
            if (response && response.payload) {
              if (response.payload.items) {
                this.listings = response.payload.items.map((item: any) => {
                  return {
                    ...item,
                    ...item.feature
                  };
                });
                console.log('Listings:', this.listings);
              } else {
                console.log(this.listings.length);
              }
            } else {
              console.error('Unexpected data format:', response);
              this.listings = null;
            }
            console.log(this.listings.length);
            
           
          },
          error => {
            console.error('Error loading listings:', error);
            this.listings = null;
          }
        );
      }

	

    initMethod() {
        console.log('in home page');
        this.route.params.subscribe(val => {
            this.searchkey = val;
            if (this.searchkey && this.searchkey.s) {
                if (this.searchkey.s != null && this.searchkey.s != 'null') {
                    console.log(this.searchkey.s);
                    this.searchArtLists(this.searchkey.s);
                }
            }
        });

        let url = window.location.href;
        console.log("in home page url:-" + url);
        console.log("in home page url:-");
        this.loading = true;
        AOS.init();
        try {
            this.usertoken = JSON.parse(this.tokenStorage.getToken() || '');
            var firstVisit = this.tokenStorage.getFirstVisitToHomePageAfterLogin();
            if (this.usertoken && firstVisit == "firstVisit") {
                console.log('first visit')
                this.socialOrigin = this.tokenStorage.getSocialOrigin();
                this.useremail = this.tokenStorage.getSocialUserEmail();
                this.authService.getUser(this.usertoken.access_token, this.socialOrigin, this.useremail).subscribe(
                    response => {
                        if (response['status'] == 'SUCCESS') {
                            this.userinfo = response['payload'];
                            if (this.userinfo.profilePercentage < 100) {
                                var url = '/update/profile/' + this.userinfo.username + '/' + this.userinfo.id + '/v-pills-home';
                                this.router.navigate([url]);
                            }
                        }
                    }
                );
                this.tokenStorage.saveFirstVisitToHomePageAfterLogin('');
            }
        } catch (error) {
            console.log("in exception of home page")
        }

        this.isLoggedIn = !!this.usertoken;

        this.dropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 10,
            limitSelection: 2,
            enableCheckAll: false,
            allowSearchFilter: true
        };
        this.dropdownSettingsmedium = {
            singleSelection: false,
            idField: 'id',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 10,
            limitSelection: 1,
            enableCheckAll: false,
            allowSearchFilter: true
        };
    }

    searchArtLists(searchkey: string) {
        this.searchResultMessage = true;
        this.artParama['searchString'] = searchkey;
        this.artParama['offset'] = 0;

        this.homeService.getArts(this.artParama).subscribe(
            response => {
                this.manageArtListResponse(response);
            }
        );
    }
    showTopIcon = false; // Property to control icon visibility

    @HostListener('window:scroll', [])
    onWindowScroll() {
      this.showTopIcon = window.scrollY > 100; // Show icon if scrolled down more than 200px
    }
  
    scrollToTop(): void {
        
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    addevent(e: any) {
        if (e.target.checked) {
            this.selectedMediumIds.push(e.target.value);
        } else {
            this.selectedMediumIds = this.selectedMediumIds.filter(id => id !== e.target.value);
        }
        console.log(this.selectedMediumIds)
        this.artParama['mediumIds'] = this.selectedMediumIds;
        this.artParama['offset'] = 0;

        this.homeService.getArts(this.artParama).subscribe(
            response => {
                this.manageArtListResponse(response);
            }
        );
    }

    manageArtListResponse(response: any) {
        if (response['status'] == 'SUCCESS') {
            this.artslist = response['payload']['userProjects'] || [];
            this.count = response['payload']['total'];
            this.tableSize = response['payload']['limit'];
            let mod1 = this.artslist.length % 4;
            let slice1 = Math.floor(this.artslist.length / 4);
            let slice2 = slice1 + slice1;
            let slice3 = slice2 + slice1;
            if (mod1 == 0) {
                this.artslist1 = this.artslist.slice(0, slice1);
                this.artslist2 = this.artslist.slice(slice1, slice2);
                this.artslist3 = this.artslist.slice(slice2, slice3);
                this.artslist4 = this.artslist.slice(slice3, this.artslist.length);
            } else if (mod1 == 1) {
                this.artslist1 = this.artslist.slice(0, slice1 + 1);
                this.artslist2 = this.artslist.slice(slice1 + 1, slice2 + 1);
                this.artslist3 = this.artslist.slice(slice2 + 1, slice3 + 1);
                this.artslist4 = this.artslist.slice(slice3 + 1, this.artslist.length);
            } else if (mod1 == 2) {
                this.artslist1 = this.artslist.slice(0, slice1 + 1);
                this.artslist2 = this.artslist.slice(slice1 + 1, slice2 + 1);
                this.artslist3 = this.artslist.slice(slice2 + 1, slice3 + 1);
                this.artslist4 = this.artslist.slice(slice3 + 1, this.artslist.length);
            } else if (mod1 == 3) {
                this.artslist1 = this.artslist.slice(0, slice1 + 1);
                this.artslist2 = this.artslist.slice(slice1 + 1, slice2 + 1);
                this.artslist3 = this.artslist.slice(slice2 + 1, slice3 + 1);
                this.artslist4 = this.artslist.slice(slice3 + 1, this.artslist.length);
            }
        }
        this.loading = false;
        this.searchResultMessage = false;
    }

    videoembed(url: string) {
        return this.embedService.embed(url);
    }
    searchuser(){
    
    }
   
    ngOnDestroy() {
        window.clearTimeout(this.timeoutId1);
        window.clearTimeout(this.timeoutId2);
      }
      
      resetTimer1() {
        if (this.timeoutId1) {
          window.clearTimeout(this.timeoutId1);
        }
        this.timeoutId1 = window.setTimeout(() => this.goToNext1(), 4000);
      }
      
      goToPrevious1(): void {
        const isFirstSlide = this.currentIndex1 === 0;
        const newIndex = isFirstSlide ? this.slides1.length - 1 : this.currentIndex1 - 1;
      
        this.resetTimer1();
        this.currentIndex1 = newIndex;
      }
      
      goToNext1(): void {
        const isLastSlide = this.currentIndex1 === this.slides1.length - 1;
        const newIndex = isLastSlide ? 0 : this.currentIndex1 + 1;
      
        this.resetTimer1();
        this.currentIndex1 = newIndex;
      }
      
      goToSlide1(slideIndex: number): void {
        this.resetTimer1();
        this.currentIndex1 = slideIndex;
      }
      
      getCurrentSlideUrl1() {
        return `url('${this.slides1[this.currentIndex1]}')`;
      }
      timeoutId1: any;
  timeoutId2: any;
      resetTimer2() {
        if (this.timeoutId2) {
          window.clearTimeout(this.timeoutId2);
        }
        this.timeoutId2 = window.setTimeout(() => this.goToNext2(), 4000);
      }
      
      goToPrevious2(): void {
        const isFirstSlide = this.currentIndex2 === 0;
        const newIndex = isFirstSlide ? this.slides2.length - 1 : this.currentIndex2 - 1;
      
        this.resetTimer2();
        this.currentIndex2 = newIndex;
      }
      
      goToNext2(): void {
        const isLastSlide = this.currentIndex2 === this.slides2.length - 1;
        const newIndex = isLastSlide ? 0 : this.currentIndex2 + 1;
      
        this.resetTimer2();
        this.currentIndex2 = newIndex;
      }
      
      
      goToSlide2(slideIndex: number): void {
        this.resetTimer2();
        this.currentIndex2 = slideIndex;
      }
      
      getCurrentSlideUrl2() {
        return `url('${this.slides2[this.currentIndex2]}')`;
      }
}
