import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '../_services/profile.service';

@Component({
  selector: 'app-profileupdate',
  templateUrl: './profileupdate.component.html',
  styleUrls: ['./profileupdate.component.scss']
})
export class ProfileUpdateComponent implements OnInit {
  profilePic: string = '../assets/images/profile.png';
  updateform: FormGroup;
  submitted = false;
  isreadonly:boolean=false
  constructor(private formBuilder: FormBuilder, private profileService: ProfileService) {}

  ngOnInit(): void {
    this.initializeForm();
    this.populateForm();

  }

  initializeForm() {
    this.updateform = this.formBuilder.group({
      fullName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mobileNumber: ['', [Validators.required]],
	  deleteAccount: [0],
	  accountEnabled: [1]
    });
  }

  populateForm() {
    const profileData = localStorage.getItem('auth-user');
    if (profileData) {
      const parsedProfileData = JSON.parse(profileData);
      const parsedProfileData1 = JSON.parse(parsedProfileData);
	  console.log(parsedProfileData1);
      this.updateform.patchValue({
        fullName: parsedProfileData1.fullName,
        email: parsedProfileData1.email,
        mobileNumber: parsedProfileData1.mobileNumber,
		deleteAccount: parsedProfileData1.deleteAccount || 0,
		accountEnabled: parsedProfileData1.accountEnabled || 1
      });
    }
  }
  get f() { return this.updateform.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.updateform.invalid) {
      return;
    }
    const payload = this.updateform.value;
    console.log("payload", payload);
    localStorage.setItem('profileData', JSON.stringify(payload));
    this.updateprofile(payload);
  }

  updateprofile(payload: any) {
    this.profileService.updateprofile(payload).subscribe(
      response => {
        if (response['status'] === 'SUCCESS') {
          console.log('Profile updated successfully!');
          const updatedProfileData = {
            ...JSON.parse(localStorage.getItem('auth-user')),
            ...payload
          };
          localStorage.setItem('auth-user', JSON.stringify(JSON.stringify(updatedProfileData)));
        } else {
          console.error('Profile update failed:', response['message']);
        }
      },
      error => {
        console.error('Error updating profile:', error);
      }
    );
  }
 
}
