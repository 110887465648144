import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HomeService } from '../_services/home.service';
import { LoadingService } from '../_services/loading.service';
import { VerifieduserComponent } from '../verifieduser/verifieduser.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SharedService } from '../shared.service';
@Component({
  selector: 'app-experties-popup',
  templateUrl: './experties-popup.component.html',
  styleUrls: ['./experties-popup.component.scss']
})
export class ExpertiesPopupComponent implements OnInit {
  experties = [];
  professions=[];
  expertiseId:any;
  professionId:any;
  icon:any
  name:any
  pId: string;
  currentIndex: number = 0;
  currentIndex1: number = 0;
  slides1 = [
    ];
    currentIndex2: number = 0;
    slides2 = [
     ];
  constructor(
    private homeService: HomeService,
    private route: ActivatedRoute,
    private dialog:MatDialog,
    private loadingService: LoadingService,
    private sharedService: SharedService
  ) {
    this.searchSubject.pipe(
      debounceTime(300) 
    ).subscribe(searchText => {
      this.searchExpertise(searchText);
    });
   }

  ngOnInit(): void {
    this.advertisement();
    this.getExperties();
    if (this.slides1.length === 0) {
      console.warn('No slides available.');
    }
    if (this.slides2.length === 0) {
      console.warn('No slides available.');
    }

    this.resetTimer1();
    this.resetTimer2();

  }
  advertisement() {
    this.homeService.advertisement().subscribe(
      (response: any) => {
        if (response && response.payload) {
          this.loadingService.hide();
          // Store only the imageUrl values in the slides1 array
          this.slides1 = response.payload.map((item: any) => item.imageUrl);
          this.slides2 = response.payload.map((item: any) => item.imageUrl);
        } else {
          this.slides1 = []; // Reset to an empty array if no response
        }
      },
      error => {
        this.loadingService.hide();
        console.error('Error loading listings:', error);
        this.slides1 = []; // Reset to an empty array on error
      }
    );
  }
  searchExpertise(searchText: string) {
    this.homeService.getExpertise(searchText).subscribe(
      (response: any) => {
        if (response && response.payload ) {
          this.loadingService.hide();
          this.experties = response.payload; 
          
        } else {
          console.error('Unexpected data format:', response);
          this.experties = [];
        }
      },
      error => {
        this.loadingService.hide();
        console.error('Error loading listings:', error);
        this.experties = [];
      }
    );
  }
  searchSubject = new Subject<string>();
  onSearch(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    const searchText = inputElement.value;
    console.log("searchText",searchText)
    this.searchSubject.next(searchText);
  }

  getExperties() {
    this.pId = this.route.snapshot.queryParamMap.get('id');
    this.homeService.getExperties(this.pId).subscribe(
      (response: any) => {
        if (response && response.payload && response.payload.length > 0) {
          this.loadingService.hide();
          this.experties = response.payload.map((item: any) => ({
            id: item.id,
            name: item.name,
            icon: item.icon,
            professionId: item.profession.id
          }));
          console.log("Experties data:", this.experties);
        } else {
        //   const name = this.sharedService.getName();
        //   const icon = this.sharedService.getIcon();
        //  this.verifieduser(this.expertiseId,this.professionId, name,icon)
          console.error('Unexpected data format:', response);
          this.experties = [];
        }
      },
      error => {
        this.loadingService.hide();
        console.error('Error loading experties:', error);
        this.experties = [];
      }
    );
  }
  verifieduser(expertiseId: string, professionId: string, name: string, icon: string) {
    this.dialog.open(VerifieduserComponent, { 
      minWidth: '27%',
      maxHeight: '100%',
      height: 'auto',
      width: 'auto',
      panelClass: 'custom-dialog-container',
      data: { 
        expertiseId, 
        professionId, 
        name, 
        icon 
      }
    });
  }
  


  resetTimer1() {
    if (this.timeoutId1) {
      window.clearTimeout(this.timeoutId1);
    }
    this.timeoutId1 = window.setTimeout(() => this.goToNext1(), 4000);
  }
  
  goToPrevious1(): void {
    const isFirstSlide = this.currentIndex1 === 0;
    const newIndex = isFirstSlide ? this.slides1.length - 1 : this.currentIndex1 - 1;
  
    this.resetTimer1();
    this.currentIndex1 = newIndex;
  }
  
  goToNext1(): void {
    const isLastSlide = this.currentIndex1 === this.slides1.length - 1;
    const newIndex = isLastSlide ? 0 : this.currentIndex1 + 1;
  
    this.resetTimer1();
    this.currentIndex1 = newIndex;
  }
  
  goToSlide1(slideIndex: number): void {
    this.resetTimer1();
    this.currentIndex1 = slideIndex;
  }
  
  getCurrentSlideUrl1() {
    return `url('${this.slides1[this.currentIndex1]}')`;
  }
  timeoutId1: any;
timeoutId2: any;
  resetTimer2() {
    if (this.timeoutId2) {
      window.clearTimeout(this.timeoutId2);
    }
    this.timeoutId2 = window.setTimeout(() => this.goToNext2(), 4000);
  }
  
  goToPrevious2(): void {
    const isFirstSlide = this.currentIndex2 === 0;
    const newIndex = isFirstSlide ? this.slides2.length - 1 : this.currentIndex2 - 1;
  
    this.resetTimer2();
    this.currentIndex2 = newIndex;
  }
  
  goToNext2(): void {
    const isLastSlide = this.currentIndex2 === this.slides2.length - 1;
    const newIndex = isLastSlide ? 0 : this.currentIndex2 + 1;
  
    this.resetTimer2();
    this.currentIndex2 = newIndex;
  }
  
  goToSlide2(slideIndex: number): void {
    this.resetTimer2();
    this.currentIndex2 = slideIndex;
  }
  
  getCurrentSlideUrl2() {
    return `url('${this.slides2[this.currentIndex2]}')`;
  }
}
