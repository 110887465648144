// shared.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private name: string = '';
  private icon: string = '';
  private id : string= '';
  setNameAndIcon(id:string,name: string, icon: string ,) {
    this.name = name;
    this.icon = icon;
    this.id=id;
  }

  getName() {
    return this.name;
  }

  getIcon() {
    return this.icon;
  }
}
