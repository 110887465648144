<div class="col-md-12 justify-content-center">
    <div class="row p-2">
        <ng-container *ngIf="listings?.length > 0; else noListings">
            <ng-container *ngFor="let listing of listings; let i = index">
                <div class="col-md-6">
                    <app-property-card [listing]="listing" [editButton]="true"></app-property-card>
                </div>
            </ng-container>
            <div *ngIf="showTopIcon" class="top-icon" (click)="scrollToTop()">
                ⬆
            </div>
        </ng-container>
        <ng-template #noListings>
          <div class="d-flex justify-content-center align-items-center w-100 vh-100">
          <app-not-found></app-not-found>
        </div>
        </ng-template>
    </div>
</div>
