<div class="row justify-content-end align-items-end custom-row">
  <button type="button" class="close" (click)="skip()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="row d-flex justify-content-center align-items-center mt-2">
    <img src="../../assets/images/logo-no-background.png" alt="" style="width: 45px;">
  </div><br>
  <div class="mt-2 entercode d-flex justify-content-center align-items-center">
    <span>Enter the code sent to :</span>&nbsp;<span class="letsingn">{{data.mobileNumber}}</span>
  </div>
  <div class="row mt-3  d-flex justify-content-center align-items-center">
    <div class="col-12">
        <div mat-dialog-content class="text-center">
            <div  id="otp"class="form-group">
              <ng-otp-input (onInputChange)="onOtpChange($event)"[config]="{length:4}"></ng-otp-input>
            </div>
            <div class="d-flex justify-content-end align-items-end mt-3 mb-3">
     
              <span class="resnd" (click)="resend()" style="cursor: pointer;;">Resend OTP</span>
        
          </div>
            <div class="row  d-flex justify-content-center align-items-center mb-2">
                <button mat-raised-button class="styled-button"  (click)="verifyOtp()">Verify OTP</button>
            </div>
          </div>
    </div>
  </div>


  
  
  