<div class="container-fluid">
  <div class="contain {{swapclass}}" id="contain">
    <div class="form-contain sign-up-contain">
      <!-- <div class="form-wrapper"> -->
        <form [formGroup]="regform" (ngSubmit)="onSubmit()" class="account account1 reg{{registrationactive}}">
          
          <h2 class="createaccount">Create Account</h2>
          <div class="row">
            <div class="col-sm-12 col-12">
              <input type="text" placeholder="Full Name" formControlName="fullName" />
              <div class="errorClass" *ngIf="submitted && f.fullName.errors">
                <div *ngIf="f.fullName.errors.required">Full Name is required</div>
               
                <div *ngIf="f.fullName.errors.pattern">Full Name must contain alphanumeric characters</div>
                <div>{{this.uservalid}}</div>
              </div>
            </div>
    
            <div class="col-sm-12 col-12">
              <input type="email" placeholder="Email" formControlName="email" (blur)="onEmailInputBlur($event)" />
              <div class="errorClass" *ngIf="submitted && f.email.errors">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
              </div>
            </div>
    
            <div class="col-sm-12 col-12">
              <input type="text" placeholder="Mobile Number" formControlName="mobileNumber" />
              <div class="errorClass" *ngIf="submitted && f.mobileNumber.errors">
                <div *ngIf="f.mobileNumber.errors.required">Mobile Number is required</div>
                <div *ngIf="f.mobileNumber.errors.mobilenumber">Mobile Number must be valid</div>
              </div>
            </div>
    
            <div class="col-sm-12 col-12">
              <input type="password" placeholder="Password" formControlName="password" />
              <div class="errorClass" *ngIf="submitted && f.password.errors">
                <div *ngIf="f.password.errors.required">Password is required</div>
                <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
              </div>
            </div>
    
            <div class="col-sm-12 col-12">
              <mat-form-field appearance="outline" class="custom-dropdown custom-form-field">
                <mat-label class="dopfont" >City</mat-label>
                <mat-select formControlName="city">
                  <mat-option *ngFor="let city of cityDataList" [value]="city.cityId">
                    {{ city.cityName }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="regform.controls['city'].hasError('required')">City is required</mat-error>
              </mat-form-field>
            </div>
    
            <div class="col-sm-12 col-12">
              <mat-form-field appearance="outline" class="custom-dropdown custom-form-field">
                <mat-label class="dopfont">Profession</mat-label>
                <mat-select formControlName="profession" (selectionChange)="onProfessionSelect($event)">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option *ngFor="let profession of professions" [value]="profession.id">
                    {{ profession.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="regform.controls['profession'].hasError('required')">Profession is required</mat-error>
              </mat-form-field>
            </div>
    
            <div class="col-sm-12 col-12">
              <mat-form-field appearance="outline" class="custom-dropdown custom-form-field">
                <mat-label class="dopfont">Expertise</mat-label>
                <mat-select formControlName="experties" multiple>
                  <mat-option *ngFor="let expertise of expertises" [value]="expertise.id">
                    {{ expertise.name }}
                  </mat-option>
                </mat-select>
                <!-- <mat-error *ngIf="regform.controls['experties'].hasError('required')">Expertise is required</mat-error> -->
              </mat-form-field>
            </div>
          </div><br><br>
          <button class="signinn">Sign Up</button>
          <div class="mobile-only" id="mobileSignIn" (click)="signInSwap()">Sign In</div>
        </form>
    
        <form [formGroup]="otpform" (ngSubmit)="onSubmitOtp()" class="account otp{{otpactive}}">
          <h3>Verify OTP</h3>
          <span>Please check your email, we have sent you a 6 digits code.</span>
          <input type="text" placeholder="Enter OTP" formControlName="otp" />
          <div class="errorClass" *ngIf="submitted && g.otp.errors">
            <div *ngIf="g.otp.errors.required">OTP is required</div>
            <div *ngIf="g.otp.errors.minlength">OTP must be at least 6 characters</div>
            <div>{{this.otpvalid}}</div>
          </div>
          <button type="submit" class="signinn">Verify OTP</button>
        </form>
      <!-- </div> -->
    </div>
    
    <div class="form-contain sign-in-contain">
      <form [formGroup]="loginform" (ngSubmit)="onLoginSubmit()" class="account login{{aciveforgot}}">
        <h2 class="welcome-back">Welcome Back</h2>
        <!-- <span>Sign In</span> -->
        <!--div class="social-contain">
          <a href="javascript:;" (click)="signInWithFB()" class="social"><i class="fa fa-facebook"
              aria-hidden="true"></i></a>
          <a href="javascript:;" (click)="signInWithGoogle()" class="social"><i class="fa fa-google"
              aria-hidden="true"></i></a>
        </div>
        <span>or use your account</span-->
        <input type="text" placeholder="Enter Username or Email" formControlName="emailusernam" />
        <div class="errorClass" *ngIf="submitted && h.emailusernam.errors">
          <div *ngIf="h.emailusernam.errors.required">Username is required</div>
        </div>
        <input type="password" placeholder="Password" formControlName="loginpassword" />
        <div class="errorClass" *ngIf="submitted && h.loginpassword.errors">
          <div *ngIf="h.loginpassword.errors.required">Password is required</div>
          <div *ngIf="h.loginpassword.errors.minlength">Password must be at least 6 characters</div>
        </div>
        <button type="submit" class="signinn  mb-2 bsk" style="margin-top: 20px;">Sign In</button>
        <!-- <a href="javascript:;" (click)="forgotSwitch()" style="color: #004aad; margin-left: 7pc; margin-top: 13px;">Forgot your password?</a> -->
        <div class="row p-3 d-flex justify-content-end">
        <a href="javascript:;" (click)="forgotSwitch()" class="d-flex justify-content-end forgot ">Forgot password?</a>
      </div>
      <div class="mobile-only" id="mobileSignUp" (click)="signUpSwap()">Sign Up</div>
      </form>
      <form [formGroup]="forgotform" (ngSubmit)="onForgotSubmit()" class="account fotgot{{aciveforgot}}">
        <h3 class="txt">Forgot Password</h3>
        <!-- <div class="social-contain">
          <a href="javascript:;" (click)="signInWithFB()" class="social"><i class="fa fa-facebook"
              aria-hidden="true"></i></a>
          <a href="javascript:;" (click)="signInWithGoogle()" class="social"><i class="fa fa-google"
              aria-hidden="true"></i></a>
        </div> -->
        <!-- <span>or use your account</span> -->
        <input type="email" placeholder="Email" formControlName="forgotemail" />
        <div class="errorClass" *ngIf="submitted && k.forgotemail.errors">
          <div *ngIf="k.forgotemail.errors.required">Email is required</div>
          <div *ngIf="k.forgotemail.errors.email">Email must be a valid email address</div>
        </div>
        <button type="submit" class="signinn mb-2"  style="margin-top: 10px;">Confirm Email</button>
        <a href="javascript:;" (click)="loginSwitch()" class="forgot-password-link1" style="margin-top: 20px;">Sign In</a>
      </form>

    </div>
    <div class="overlay-contain">
      <div class="overlay">
        <div class="overlay-panel overlay-left">
          <h3>Welcome Back!</h3>
          <p>To keep connected with us please login with your account</p>
          <button class="ghost" id="signIn" (click)="signInSwap()">Sign In</button>
        </div>
        <div class="overlay-panel overlay-right">
          <h1>Hello, Friend!</h1>
          <p>Enter your details and start your journey with us</p>
          <button class="ghost" id="signUp" (click)="signUpSwap()">Sign Up</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading" class="loader"></div> 