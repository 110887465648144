// user-profile.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  private userProfileUpdated = new Subject<void>();

  notifyUserProfileUpdate() {
    this.userProfileUpdated.next();
  }

  getUserProfileUpdateNotifier() {
    return this.userProfileUpdated.asObservable();
  }
}
