import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyOwnerComponent } from '../property-owner/property-owner.component';
import { MatDialog } from '@angular/material/dialog';
import { HomeService } from '../_services/home.service';
import { LoadingService } from '../_services/loading.service';

@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss']
})
export class PropertyDetailsComponent implements OnInit {
  listing: any;
  listingData: any;
  currentIndex: number = 0;
  data: any;
  id: number;
  UserDetails: any;
  Images: string[] = [];
  asking: number;
  size: number;
  total: number;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private homeService: HomeService,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private zone: NgZone
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      console.log('params', params);

      this.id = params['id'];
      if (this.id) {
        this.propertById();
      } else {
        console.error('No Property ID found in query params');
      }
    });
  }

  propertById() {
    this.loadingService.show();
    this.homeService.getPropertyById(this.id).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.loadingService.hide();
        console.log('response', response);
        this.listingData = response?.payload;
        this.UserDetails = response?.payload?.user;
        this.Images = response.payload.mediaGroup.map(item => item.url);
        console.log('listingData', this.listingData);
        this.propertyper(this.listingData)
      } else {
        console.error('Response status is not SUCCESS');
      }
    }, (error) => {
      this.loadingService.hide();
      console.error('API call error', error);
    });
  }

  propertyper(listingData: any) {
    this.asking = listingData.asking;
    const sizeString = listingData.feature.size;
    this.size = parseFloat(sizeString.replace(/[^0-9.]/g, ''));
    if (this.size !== 0) {
      this.total = Math.round(this.asking / this.size);
    } else {
      console.error('Size cannot be zero');
      this.total = 0; 
    }
  }
  
 
  openContactDetailsDialog(listingData): void {
    const lastdate = localStorage.getItem('auth-user');
    if (lastdate) {
      try {
        const lastdatedata = JSON.parse(lastdate);
        const lastdatedata1 = JSON.parse(lastdatedata);
        const lastLoginString = lastdatedata1.lastLogin;
        console.log("lastLogin", lastLoginString);
        const lastLogin = this.parseDate(lastLoginString);
        const currentTime = new Date();
        if (lastLogin) {
          const adjustedTime = new Date(currentTime.getTime() - (5 * 60 * 60 * 1000) - (30 * 60 * 1000));
          const timeDifferenceInSeconds = Math.floor((adjustedTime.getTime() - lastLogin.getTime()) / 1000);
          console.log("count 0 to 1800", timeDifferenceInSeconds);
          if (timeDifferenceInSeconds <= 1800) {
            this.dialog.open(PropertyOwnerComponent, {
              data: listingData,
              width: '1020px',
              height:'70%'
            });
          }
         else {
          console.error('Invalid date parsed:', lastLogin);
          this.router.navigate(['/login']);
        }
      }
      } catch (error) {
        console.error('Error parsing lastdate:', error);
        this.router.navigate(['/login']);
      }
    } else {
      console.log('User is not logged in.');
      this.router.navigate(['/login']);
    }
  }
  
  parseDate(dateString: string): Date {
    const parts = dateString.split(' ');
    const dateParts = parts[0].split('-');
    const timeParts = parts[1].split(':');
    return new Date(
      parseInt(dateParts[2], 10), 
      parseInt(dateParts[1], 10) - 1,  
      parseInt(dateParts[0], 10),  
      parseInt(timeParts[0], 10),  
      parseInt(timeParts[1], 10),  
      parseInt(timeParts[2], 10)  
    );

  }

  ngAfterContentChecked(): void {
  }
}
