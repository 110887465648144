import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { HomeService } from '../_services/home.service';
import { LoadingService } from '../_services/loading.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-amount-dialog',
  templateUrl: './amount-dialog.component.html',
  styleUrls: ['./amount-dialog.component.scss']
})
export class AmountDialogComponent implements OnInit {


  professionId: number;
  expertiseId: number;
  amount: number;
  payload:any

  constructor(
    private homeService: HomeService,
    private loadingService: LoadingService,
    private router:Router,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<AmountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { professionId: number; expertiseId: number; amount: number }
  ) {
     // Assign the received data to the component properties
     this.professionId = data.professionId;
     this.expertiseId = data.expertiseId;
     this.amount = data.amount;
   }

  ngOnInit(): void {
    console.log('Received data in ngOnInit:', {
      professionId: this.professionId,
      expertiseId: this.expertiseId,
      amount: this.amount
    });
    this.BreakdownAmount(this.expertiseId,this.professionId,this.amount)
  }

  closeModal() {
    // Logic to close the modal, for example:
    this.dialogRef.close(); // If using Angular Material Dialog
    // or
  }
  handleOk(){
    this.dialogRef.close();
  }

  BreakdownAmount(expetiseId,eprofressionId,amount) {
  
    this.homeService.BreakdownAmount(expetiseId,eprofressionId,amount).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          console.log('response',response);
           this.payload=response.payload
          
        } else {
          console.error('Failed to fetch state data:', response);
        }
      },
      (error) => {
        console.error('Error fetching state data:', error);
      }
    );
  }

}
