import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { HomeService } from '../_services/home.service';
import { LoadingService } from '../_services/loading.service';

@Component({
  selector: 'app-property-slide',
  templateUrl: './property-slide.component.html',
  styleUrls: ['./property-slide.component.scss'],
  providers: [CurrencyPipe]  // Provide CurrencyPipe
})
export class PropertySlideComponent implements OnInit {
  @Output() payloadEvent = new EventEmitter<any>();
  hasMore: boolean = false;
  isExpanded: boolean = true;
  selectedMinValue: number | null = null;
  selectedMaxValue: number | null = null;
  selectedBudget: string = '';
  panelOpenState = false;
  userfilter: string[] = [];
  showAllBedrooms: boolean = false;
  selectedType: string[] = [];
  isFilterSidebarOpen = false;

  constructor(private cdr: ChangeDetectorRef, private currencyPipe: CurrencyPipe,
    private homeService: HomeService,
    private loadingService: LoadingService,
     private cd: ChangeDetectorRef,
  ) {}
  Profession = [];
  professions = []; 
  defaultProfession:any
  expertise = [];
  ngOnInit(): void {
    this.fetchAllProfession();
    // this.fetchAllExpertise();
   }
   fetchAllProfession() {
    this.loadingService.show();
  
    this.homeService.fetchAllProfession().subscribe(
      (response: any) => {
        this.loadingService.hide();
  
        if (response && response.payload) {
          this.Profession = response.payload.sort((a, b) => a.name.localeCompare(b.name));
  
           this.defaultProfession = this.Profession.find(prof => prof.name === "Chartered Accountant");
  
          if (this.defaultProfession) {
            this.selectedProfession = this.defaultProfession;  
            this.selectProfession(this.defaultProfession);    
          }
        } else {
          console.error('Unexpected data format:', response);
          this.Profession = [];
        }
      },
      error => {
        this.loadingService.hide();
        console.error('Error loading professions:', error);
        this.Profession = [];
      }
    );
  }
  
  
  
  // fetchAllExpertise() {
  //   this.loadingService.show();
  
  //   this.homeService.fetchAllExperties().subscribe(
  //     (response: any) => {
  //       this.loadingService.hide(); 
  
  //       if (response && response.payload) {
  //         this.expertise = response.payload;
  //       } else {
  //         console.error('Unexpected data format:', response);
  //         this.expertise = [];
  //       }
  //     },
  //     error => {
  //       this.loadingService.hide(); 
  //       console.error('Error loading listings:', error);
  //       this.expertise = [];
  //     }
  //   );
  // }
  fetchAllExperties(id) {
    this.homeService.fetchidExperties(id).subscribe(
      (response: any) => {
        if (response && response.payload) {
          this.loadingService.hide();
          this.expertise = response.payload.sort((a, b) => a.name.localeCompare(b.name));
          this.cd.detectChanges();
        } else {
          console.error('Unexpected data format:', response);
          this.expertise = [];
        }
        this.cd.detectChanges();
      },
      error => {
        this.loadingService.hide();
        console.error('Error loading listings:', error);
        this.expertise = [];
      }
    );
  }
  
  filterProfessions() {
    return this.Profession.filter(profession =>
      profession.name.toLowerCase().includes(this.searchProfessionTerm.toLowerCase())
    );
  }

  clearSearch() {
    this.searchProfessionTerm = '';
  }
  
  searchProfessionTerm: string = '';
  searchexpertiseTerm: string = '';

  selectedProfession: any;
  selectedexpertise: string;




  filterexpertise() {
    return this.expertise.filter(expertise =>
      expertise.name.toLowerCase().includes(this.searchexpertiseTerm.toLowerCase())
    );
  }

  budgetOptions = [
    { value: 50000, label: '50,000' },
    { value: 500000, label: '5 Lacs' },
    { value: 500000, label: '5 Lacs' },
    { value: 1000000, label: '10 Lacs' },
    { value: 1500000, label: '15 Lacs' },
    { value: 2500000, label: '25 Lacs' },
    { value: 5000000, label: '50 Lacs' },
    { value: 8000000, label: '80 Lacs' },
    { value: 10000000, label: '1 Cr' },
    { value: 100000000, label: '100+ Crores'}
  ];



  payload: any = {};

  toggleMore() {
    this.hasMore = !this.hasMore;
  }

  selectPropertyType(propertyType) {
    propertyType.selected = !propertyType.selected;
    if (propertyType.selected) {
      this.userfilter.push(propertyType.name);
      if (!this.selectedType.includes("type")) {
        this.selectedType.push("type");
      }
    } else {
      this.userfilter = this.userfilter.filter(name => name !== propertyType.name);
      if (this.userfilter.length === 0) {
        this.selectedType = this.selectedType.filter(type => type !== "type");
      }
    }

    this.updatePayload();
    this.cdr.detectChanges();
  }

  selectBedroom(bedroom) {
    console.log(bedroom);

    bedroom.selected = !bedroom.selected;
    if (bedroom.selected) {
      this.userfilter.push(bedroom.value);
      this.selectedType.push("noOfBadroom");

    } else {
      this.userfilter = this.userfilter.filter(name => name !== bedroom.name);
      this.selectedType = this.selectedType.filter(noOfBadroom => noOfBadroom !== "noOfBadroom");

    }
    this.updatePayload();
    this.cdr.detectChanges();
  }

  onBudgetChange() {
    if (this.selectedMinValue !== null && this.selectedMaxValue !== null) {
        const fromAsking = this.formatCurrency(this.selectedMinValue);
        const toAsking = this.formatCurrency(this.selectedMaxValue);
        this.selectedBudget = `${fromAsking} - ${toAsking}`;
        console.log('selectedBudget', this.selectedBudget);
        
        this.selectedType.push("asking");
        if (!this.userfilter.includes(this.selectedBudget)) {
            this.userfilter.push(this.selectedBudget);
        }
        this.updatePayload();
        this.cdr.detectChanges();
    }
}

formatCurrency(value: number): string {
    // Transform the value using CurrencyPipe
    const formattedValue = this.currencyPipe.transform(value, 'INR', 'symbol', '1.0-0');
    // Remove commas and the currency symbol
    return formattedValue?.replace(/[₹,]/g, '') ?? '';
}

 removeFilter(filter: string) {
  console.log(filter);

  this.userfilter = this.userfilter.filter(name => name !== filter);

  this.updatePayload();
  this.cdr.detectChanges();
}

  removeBudget() {
    this.selectedBudget = '';
    this.selectedMinValue = null;
    this.selectedMaxValue = null;
    this.updatePayload();
    this.cdr.detectChanges();
  }

  clearSelection() {
    this.defaultProfession='';
    console.log(this.defaultProfession);
    
    this.selectedProfession = '';
    this.Profession.forEach(profession => profession.selected = false);
    this.expertise = [];
    this.selectedexpertise = '';
    this.expertise.forEach(expertise => expertise.selected = false);
  
    this.removeBudget();
    this.userfilter = [];
    this.selectedType = [];
  
    this.updatePayload();
    this.cdr.detectChanges();
    this.isFilterSidebarOpen = false;
  }
  
  updatePayload() {
    this.payload = {
      filterName: this.selectedType,
      filterValue: this.userfilter,
    };
  
    if (this.selectedMinValue !== null && this.selectedMaxValue !== null) {
      this.payload.minBudget = this.selectedMinValue;
      this.payload.maxBudget = this.selectedMaxValue;
    }
  
    // Add selectedProfession and selectedexpertise to the payload
    if (this.selectedProfession) {
      this.payload.selectedProfession = this.selectedProfession;
      if (!this.userfilter.includes(this.selectedProfession)) {
        this.userfilter.push(this.selectedProfession);
      }
    }
  
    if (this.selectedexpertise) {
      this.payload.selectedexpertise = this.selectedexpertise;
      if (!this.userfilter.includes(this.selectedexpertise)) {
        this.userfilter.push(this.selectedexpertise);
      }
    }
  
    console.log(this.payload);
    this.payloadEvent.emit(this.payload);
  }
  
  applyFilter() {
    console.log('Final store data', this.payload);
    this.payloadEvent.emit(this.payload);
    this.isFilterSidebarOpen = false;
  }

  toggleShowAllBedrooms() {
    this.showAllBedrooms = !this.showAllBedrooms;
  }


  
  toggleFilterSidebar() {
    this.isFilterSidebarOpen = !this.isFilterSidebarOpen;
  }


  selectProfession(selectedItem: any) {
    // If no profession is selected, default to "Chartered Accountant"
    if (!selectedItem) {
      selectedItem = this.Profession.find(prof => prof.name === "Chartered Accountant");
    }
  
    this.selectedProfession = selectedItem.name;
  
    console.log('Selected Profession Object:', selectedItem);
  
    if (selectedItem) {
      console.log('Selected ID:', selectedItem.id);
      console.log('Selected Name:', selectedItem.name);
      this.fetchAllExperties(selectedItem.id);
    }
  
    this.userfilter = this.userfilter.filter(filter => !this.Profession.some(prof => prof.name === filter));
  
    this.userfilter.push(this.selectedProfession);
  
    if (this.selectedType.includes('expertise')) {
      this.selectedType = this.selectedType.filter(type => type !== 'expertise');
    }
  
    if (!this.selectedType.includes("profession")) {
      this.selectedType.push("profession");
    }
  
    // Update the payload
    this.updatePayload();
  }
  
  
  selectexpertise(selectedItem: string) {
    this.selectedexpertise = selectedItem;

    // Clear other filters if new selection is made
    this.userfilter = this.userfilter.filter(filter => !this.expertise.some(exp => exp.name === filter));
    this.userfilter.push(this.selectedexpertise);

    if (this.selectedType.includes('profession')) {
      this.selectedType = this.selectedType.filter(type => type !== 'profession');
    }

    if (!this.selectedType.includes("expertise")) {
      this.selectedType.push("expertise");
    }

    this.updatePayload();
  }

}
