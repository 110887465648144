import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-property-owner',
  templateUrl: './property-owner.component.html',
  styleUrls: ['./property-owner.component.scss']
})
export class PropertyOwnerComponent implements OnInit {
  listingData: any;
  formattedDate: string;
  contactForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public dialogRef: MatDialogRef<PropertyOwnerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const dateString = "2023-07-23T10:20:30Z";
    this.formattedDate = this.formatDate(dateString);
    this.contactForm = this.fb.group({
      reasonToBuy: ['', Validators.required],
      propertyDealer: ['', Validators.required],
      name: ['', [Validators.required, Validators.minLength(2)]],
      mobile: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
      email: ['', [Validators.required, Validators.email]],
      planningToBuy: [''],
      homeLoan: [false],
      siteVisits: [false],
      terms: [false]
    });
  }

  ngOnInit(): void {
    this.listingData = this.data;
    console.log("this.listingDatathis.listingData", this.listingData);
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    const day = date.getDate().toString().padStart(2, '0');
    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${day}TH ${month}, ${year}`;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.contactForm.valid) {
      const payload = this.contactForm.value;
      console.log(payload);
    } else {
      console.log("Form is not valid");
    }
  }
}
