<div class="col-md-12" style="padding-left: 200px;">
    <ng-container *ngIf="listings?.length > 0; else noListings">
      <ng-container *ngFor="let listing of listings;">
        <app-property-card [listing]="listing" [editButton]="true"></app-property-card>
      </ng-container>
      <div *ngIf="showTopIcon" class="top-icon" (click)="scrollToTop()">
        ⬆
      </div>
    </ng-container>
    <ng-template #noListings>
      <app-not-found></app-not-found>
    </ng-template>
  
  </div>