

<div class="page-container">

  <app-header></app-header>
  <app-menu></app-menu><br>
  <div class="container-fluid d-flex flex-column align-items-center " style="margin-top: 3pc;">
    <!-- Avatar/Profile Picture -->
    <div class="avatar-banner">
      <img alt="Profile Picture" src="{{profilePic}}" class="profile-pic" />
    </div>
  
    <!-- Form -->
    <div class="contact-form-wrapper mt-5">
      <div class="contact-form">
        <h3>Your Contact Details</h3>
        <form [formGroup]="updateform" (ngSubmit)="onSubmit()">
          <h2 class="createaccount">Update Your Profile</h2>
  
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Full Name" formControlName="fullName" />
            <div class="errorClass" *ngIf="submitted && f.fullName.errors">
              <div *ngIf="f.fullName.errors.required">Full Name is required</div>
              <div *ngIf="f.fullName.errors.minlength">Full Name must be at least 8 characters</div>
              <div *ngIf="f.fullName.errors.pattern">Full Name must contain alphanumeric characters</div>
            </div>
          </div>
  
          <div class="form-group">
            <input type="email" class="form-control" placeholder="Email" formControlName="email" />
            <div class="errorClass" *ngIf="submitted && f.email.errors">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
            </div>
          </div>
  
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Mobile Number" formControlName="mobileNumber" />
            <div class="errorClass" *ngIf="submitted && f.mobileNumber.errors">
              <div *ngIf="f.mobileNumber.errors.required">Mobile Number is required</div>
              <div *ngIf="f.mobileNumber.errors.pattern">Mobile Number must be valid</div>
            </div>
          </div>
  
          <div class="button-group">
            <button class="btn" type="submit">Update & Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>