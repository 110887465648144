import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HomeService } from '../_services/home.service';
import { LoadingService } from '../_services/loading.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { VerifiedotpComponent } from '../verifiedotp/verifiedotp.component';
import { VerifieduserComponent } from '../verifieduser/verifieduser.component';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ExpertiesPopupComponent } from '../experties-popup/experties-popup.component';
import { SharedService } from '../shared.service';
@Component({
  selector: 'app-expension-card',
  templateUrl: './expension-card.component.html',
  styleUrls: ['./expension-card.component.scss']
})
export class ExpensionCardComponent implements OnInit {
  professions = [];
  // imageurl=[];
  selectedIcon: string | null = null;

  selectedName: string = '';
  currentIndex: number = 0;
    currentIndex1: number = 0;
    slides1 = [];
     currentIndex2: number = 0;
     slides2 = [];
  searchSubject = new Subject<string>();
  panelOpenState: boolean = false;
  ngAfterViewInit() {
    this.professions.forEach(profession => {
      profession.expanded = true;
    });
  }
  constructor(
    private homeService: HomeService,
    private loadingService: LoadingService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private sharedService: SharedService
  ) {
    this.searchSubject.pipe(
      debounceTime(300) 
    ).subscribe(searchText => {
      this.searchExpertise(searchText);
    });
  }

  ngOnInit(): void {
    this.advertisement()
    this.getProfession();
    if (this.slides1.length === 0) {
      console.warn('No slides available.');
    }
    if (this.slides2.length === 0) {
      console.warn('No slides available.');
    }

    this.resetTimer1();
    this.resetTimer2();


  }
  getProfession() {
    this.homeService.getProfession().subscribe(
      (response: any) => {
        if (response && response.payload) {
          this.loadingService.hide();
          this.professions = response.payload.map((item: any) => ({
            id: item.id,
            name: item.name,
            icon: item.icon,
            expertiseCount:item.expertiseCount,
          }));
          this.selectedName = this.professions[0].name;
          console.log("Professions data:", this.professions);
        } else {
          console.error('Unexpected data format:', response);
          this.professions = [];
        }
      },
      error => {
        this.loadingService.hide();
        console.error('Error loading listings:', error);
        this.professions = [];
      }
    );
  }
  
  // slides1 = [];
  advertisement() {
    this.homeService.advertisement().subscribe(
      (response: any) => {
        if (response && response.payload) {
          this.loadingService.hide();
          // Store only the imageUrl values in the slides1 array
          this.slides1 = response.payload.map((item: any) => item.imageUrl);
          this.slides2 = response.payload.map((item: any) => item.imageUrl);
        } else {
          this.slides1 = []; // Reset to an empty array if no response
        }
      },
      error => {
        this.loadingService.hide();
        console.error('Error loading listings:', error);
        this.slides1 = []; // Reset to an empty array on error
      }
    );
  }
  
  // expertiespopup(pId ,icon, name){
  //   this.sharedService.setNameAndIcon(name, icon);
  //   this.router.navigate(['/expertiescard'], { queryParams: { id: pId } });
  // }
  

  // getExpertiseByProfession() {
  //   this.homeService.getExpertiseByProfession().subscribe(
  //     (response: any) => {
  //       if (response && response.payload && response.payload[0].professions) {
  //         this.loadingService.hide();
  //         this.professions = Object.values(response.payload[0].professions); 
          
  //       } else {
  //         console.error('Unexpected data format:', response);
  //         this.professions = [];
  //       }
  //     },
  //     error => {
  //       this.loadingService.hide();
  //       console.error('Error loading listings:', error);
  //       this.professions = [];
  //     }
  //   );
  // }

  onSearch(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    const searchText = inputElement.value;
    this.searchSubject.next(searchText);
  }
  
  searchExpertise(searchText: string) {
    this.homeService.getProfesion(searchText).subscribe(
      (response: any) => {
        if (response && response.payload) {
          this.loadingService.hide();
          // this.professions = Object.values(response.payload[0].professions); 
          this.professions = response.payload;
        
          
        } else {
          console.error('Unexpected data format:', response);
          this.professions = [];
        }
      },
      error => {
        this.loadingService.hide();
        console.error('Error loading listings:', error);
        this.professions = [];
      }
    );
  }


  resetTimer1() {
    if (this.timeoutId1) {
      window.clearTimeout(this.timeoutId1);
    }
    this.timeoutId1 = window.setTimeout(() => this.goToNext1(), 4000);
  }
  
  goToPrevious1(): void {
    const isFirstSlide = this.currentIndex1 === 0;
    const newIndex = isFirstSlide ? this.slides1.length - 1 : this.currentIndex1 - 1;
  
    this.resetTimer1();
    this.currentIndex1 = newIndex;
  }
  
  goToNext1(): void {
    const isLastSlide = this.currentIndex1 === this.slides1.length - 1;
    const newIndex = isLastSlide ? 0 : this.currentIndex1 + 1;
  
    this.resetTimer1();
    this.currentIndex1 = newIndex;
  }
  
  goToSlide1(slideIndex: number): void {
    this.resetTimer1();
    this.currentIndex1 = slideIndex;
  }
  
  getCurrentSlideUrl1() {
    return `url('${this.slides1[this.currentIndex1]}')`;
  }
  timeoutId1: any;
timeoutId2: any;
  resetTimer2() {
    if (this.timeoutId2) {
      window.clearTimeout(this.timeoutId2);
    }
    this.timeoutId2 = window.setTimeout(() => this.goToNext2(), 4000);
  }
  
  goToPrevious2(): void {
    const isFirstSlide = this.currentIndex2 === 0;
    const newIndex = isFirstSlide ? this.slides2.length - 1 : this.currentIndex2 - 1;
  
    this.resetTimer2();
    this.currentIndex2 = newIndex;
  }
  
  goToNext2(): void {
    const isLastSlide = this.currentIndex2 === this.slides2.length - 1;
    const newIndex = isLastSlide ? 0 : this.currentIndex2 + 1;
  
    this.resetTimer2();
    this.currentIndex2 = newIndex;
  }
  
  goToSlide2(slideIndex: number): void {
    this.resetTimer2();
    this.currentIndex2 = slideIndex;
  }
  
  getCurrentSlideUrl2() {
    return `url('${this.slides2[this.currentIndex2]}')`;
  }
  verifieduser(professionId: string, name: string, icon: string) {
    this.dialog.open(VerifieduserComponent, { 
      minWidth: '27%',
      maxHeight:'100%',
      height: 'auto',
      width: 'auto',
      panelClass: 'custom-dialog-container',
      data: { 
        professionId,
        name,
        icon 
      } 
    });
  }
  
  expertiespopup(id: string, icon: string, name: string, expertiseCount: number) {
    this.sharedService.setNameAndIcon(name, icon, id);
    if (expertiseCount === 0) {
      this.verifieduser( id, name, icon,);
    } else {
      this.router.navigate(['/expertiescard'], { queryParams: { id: id } });
    }
  }
  
}
