import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError, concatMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { TokenStorageService } from './token-storage.service';
import { HomeService } from './home.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	constructor(private tokenStorage: TokenStorageService,
		private http: HttpClient,private homeService: HomeService) { }

	userRegister(formval) {

		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/user/register`, JSON.stringify(formval), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
							icon: 'error',
							title: 'Invalid request',
							showConfirmButton: false,
							timer: 6000
						});*/
					return throwError(error);
				})
			);
	}
	uniqueUsername(username) {
		let uname = { "username": username };
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/user/register`, JSON.stringify(uname), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
							icon: 'error',
							title: 'Invalid request',
							showConfirmButton: false,
							timer: 6000
						});*/
					return throwError(error);
				})
			);
	}
	verifyOtp(emailid, otp) {

		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/user/verify/otp?email=${emailid}&otp=${otp}`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
							icon: 'error',
							title: 'Invalid request',
							showConfirmButton: false,
							timer: 6000
						});*/
					return throwError(error);
				})
			);
	}
	
	loginUser(emailid, pass) {

		let httpheader = new HttpHeaders()
		.set('Authorization', 'Basic d2ViOnNlY3JldA==');
	  let options = {
		headers: httpheader
	  };
		// return this.http.post(`${environment.apiUrl}/user/login?platform=portal&userName=${emailid}&password=${pass}&countryCode=91`,null,options)
		// 	.pipe(tap(data => { return data }), catchError(error => {
		// 		Swal.fire({
		// 			icon: 'error',
		// 			title: 'Invalid credentials',
		// 			showConfirmButton: false,
		// 			timer: 600000
		// 		});
		// 		return throwError(error);
		// 	}));
		return this.http.post<any>(`${environment.apiUrl}/user/login?platform=portal&userName=${emailid}&password=${pass}&countryCode=91`,null,options)
	}

	loginUser2(emailid, pass) {

		let uname = [{}];
		const headers = new HttpHeaders({
			'Authorization': 'Basic d2ViOnNlY3JldA==',
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/oauth/token?username=${emailid}&password=${pass}&grant_type=password`, JSON.stringify(uname), {
			headers: headers
		})
			.pipe(
				concatMap(
					(res) =>
						this.getUser2(res, null, null)));
	}

	forgotPassword(emailid) {

		let uname = [{}];
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/user/forgot/password?email=${emailid}`, JSON.stringify(uname), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
							icon: 'error',
							title: 'Invalid request',
							showConfirmButton: false,
							timer: 6000
						});*/
					return throwError(error);
				})
			);
	}
	resetpassword(oldpass, newpass, emailid) {

		let uname = { "currentPassword": oldpass, "newPassword": newpass };
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/user/reset/password?email=${emailid}`, JSON.stringify(uname), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
							icon: 'error',
							title: 'Invalid request',
							showConfirmButton: false,
							timer: 6000
						});*/
					return throwError(error);
				})
			);
	}
	getUser2(res, origin, email) {
		if (res['access_token'] != null) {
			this.tokenStorage.saveToken(JSON.stringify(res));
			this.tokenStorage.saveSocialOrigin(null);
			this.tokenStorage.saveSocialUserEmail(null);

			const headers = new HttpHeaders({
				'Authorization': 'Basic ' + res['access_token'],
				'Content-Type': 'application/json'
			});
			return this.http.get(`${environment.apiUrl}/user/authorities?socialOrigin=${origin}&email=${email}`, {
				headers: headers
			})
				.pipe(
					tap(data => {
						this.tokenStorage.saveUser(JSON.stringify(data['payload']));
						return data
					}), catchError(error => {
						/*Swal.fire({
							icon: 'error',
							title: 'Invalid request',
							showConfirmButton: false,
							timer: 6000
						});*/
						return throwError(error);
					})
				);
		}

	}
	getUser(token, origin, email) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + token,
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/user/authorities?socialOrigin=${origin}&email=${email}`, {
			headers: headers
		})
			.pipe(
				tap(data => {
					this.tokenStorage.saveUser(JSON.stringify(data['payload']));
					return data;

				}), catchError(error => {
					/*Swal.fire({
							icon: 'error',
							title: 'Invalid request',
							showConfirmButton: false,
							timer: 6000
						});*/
					return throwError(error);
				})
			);
	}

	getProfileData() {
		let Token=localStorage.getItem('access_token')
		console.log('Token',Token);
		
			const headers = new HttpHeaders({
				'Authorization': 'Basic '+Token,
				'Content-Type': 'application/json'
			});
			return this.http.get(`${environment.apiUrl}/user/profile`,{
				headers: headers
			})
				.pipe(
					tap(data => { return data }), catchError(error => {
					
						return throwError(error);
					})
				);
		}

	sendemailotp(data:any){
		let httpheader = new HttpHeaders().set('Authorization', 'Basic d2ViOnNlY3JldA==');
		let options = {headers: httpheader};
		return this.http.post<any>(`${environment.apiUrl}/user/send/otp?email=${data}`, null)
	  } 

}
