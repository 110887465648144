import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HomeService } from '../_services/home.service';
import { LoadingService } from '../_services/loading.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { VerifiedotpComponent } from '../verifiedotp/verifiedotp.component';
import { VerifieduserComponent } from '../verifieduser/verifieduser.component';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ExpertiesPopupComponent } from '../experties-popup/experties-popup.component';
@Component({
  selector: 'app-expension-card',
  templateUrl: './expension-card.component.html',
  styleUrls: ['./expension-card.component.scss']
})
export class ExpensionCardComponent implements OnInit {
  professions = [];
  currentIndex: number = 0;
    currentIndex1: number = 0;
    slides1 = [
      'https://w0.peakpx.com/wallpaper/163/36/HD-wallpaper-white-ganpati-statue-in-blur-background-ganesh.jpg',
       'https://thumbs.dreamstime.com/b/medical-tech-science-innovative-iot-global-healthcare-ai-technology-doctor-telehealth-telemedicine-service-medical-tech-210434735.jpg',
       'https://www.shutterstock.com/image-photo/medical-technology-doctor-use-ai-260nw-2353590953.jpg',
       'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEHyeuE7atThbIyuqzQ4k6mXUiIsOW8LNg_g&s',
       'https://www.shutterstock.com/image-vector/agile-development-optimisation-concept-software-260nw-2355465819.jpg'
     ];
     currentIndex2: number = 0;
     slides2 = [
       'https://www.shutterstock.com/image-vector/agile-development-optimisation-concept-software-260nw-2355465819.jpg',
       'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEHyeuE7atThbIyuqzQ4k6mXUiIsOW8LNg_g&s',
       'https://thumbs.dreamstime.com/b/medical-tech-science-innovative-iot-global-healthcare-ai-technology-doctor-telehealth-telemedicine-service-medical-tech-210434735.jpg',
       'https://www.shutterstock.com/image-photo/medical-technology-doctor-use-ai-260nw-2353590953.jpg',
       'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQWkb_3HLznguhvVdiwECMjh60QmHEIHvWdA&s'
      ];
  searchSubject = new Subject<string>();
  panelOpenState: boolean = false;
  ngAfterViewInit() {
    this.professions.forEach(profession => {
      profession.expanded = true;
    });
  }
  constructor(
    private homeService: HomeService,
    private loadingService: LoadingService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    this.searchSubject.pipe(
      debounceTime(300) 
    ).subscribe(searchText => {
      this.searchExpertise(searchText);
    });
  }

  ngOnInit(): void {
    this.getProfession();
    if (this.slides1.length === 0) {
      console.warn('No slides available.');
    }
    if (this.slides2.length === 0) {
      console.warn('No slides available.');
    }

    this.resetTimer1();
    this.resetTimer2();


  }
  getProfession() {
    this.homeService.getProfession().subscribe(
      (response: any) => {
        if (response && response.payload) {
          this.loadingService.hide();
          this.professions = response.payload.map((item: any) => ({
            id: item.id,
            name: item.name,
            icon: item.icon,
          }));
          console.log("Professions data:", this.professions);
        } else {
          console.error('Unexpected data format:', response);
          this.professions = [];
        }
      },
      error => {
        this.loadingService.hide();
        console.error('Error loading listings:', error);
        this.professions = [];
      }
    );
  }
  
  
  // getExpertiseByProfession() {
  //   this.homeService.getExpertiseByProfession().subscribe(
  //     (response: any) => {
  //       if (response && response.payload && response.payload[0].professions) {
  //         this.loadingService.hide();
  //         this.professions = Object.values(response.payload[0].professions); 
          
  //       } else {
  //         console.error('Unexpected data format:', response);
  //         this.professions = [];
  //       }
  //     },
  //     error => {
  //       this.loadingService.hide();
  //       console.error('Error loading listings:', error);
  //       this.professions = [];
  //     }
  //   );
  // }

  onSearch(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    const searchText = inputElement.value;
    this.searchSubject.next(searchText);
  }
  
  searchExpertise(searchText: string) {
    this.homeService.getProfesion(searchText).subscribe(
      (response: any) => {
        if (response && response.payload) {
          this.loadingService.hide();
          // this.professions = Object.values(response.payload[0].professions); 
          this.professions = response.payload;
        
          
        } else {
          console.error('Unexpected data format:', response);
          this.professions = [];
        }
      },
      error => {
        this.loadingService.hide();
        console.error('Error loading listings:', error);
        this.professions = [];
      }
    );
  }
  expertiespopup(pId){
    this.router.navigate(['/expertiescard'], { queryParams: { id: pId } });
  }
  resetTimer1() {
    if (this.timeoutId1) {
      window.clearTimeout(this.timeoutId1);
    }
    this.timeoutId1 = window.setTimeout(() => this.goToNext1(), 4000);
  }
  
  goToPrevious1(): void {
    const isFirstSlide = this.currentIndex1 === 0;
    const newIndex = isFirstSlide ? this.slides1.length - 1 : this.currentIndex1 - 1;
  
    this.resetTimer1();
    this.currentIndex1 = newIndex;
  }
  
  goToNext1(): void {
    const isLastSlide = this.currentIndex1 === this.slides1.length - 1;
    const newIndex = isLastSlide ? 0 : this.currentIndex1 + 1;
  
    this.resetTimer1();
    this.currentIndex1 = newIndex;
  }
  
  goToSlide1(slideIndex: number): void {
    this.resetTimer1();
    this.currentIndex1 = slideIndex;
  }
  
  getCurrentSlideUrl1() {
    return `url('${this.slides1[this.currentIndex1]}')`;
  }
  timeoutId1: any;
timeoutId2: any;
  resetTimer2() {
    if (this.timeoutId2) {
      window.clearTimeout(this.timeoutId2);
    }
    this.timeoutId2 = window.setTimeout(() => this.goToNext2(), 4000);
  }
  
  goToPrevious2(): void {
    const isFirstSlide = this.currentIndex2 === 0;
    const newIndex = isFirstSlide ? this.slides2.length - 1 : this.currentIndex2 - 1;
  
    this.resetTimer2();
    this.currentIndex2 = newIndex;
  }
  
  goToNext2(): void {
    const isLastSlide = this.currentIndex2 === this.slides2.length - 1;
    const newIndex = isLastSlide ? 0 : this.currentIndex2 + 1;
  
    this.resetTimer2();
    this.currentIndex2 = newIndex;
  }
  
  goToSlide2(slideIndex: number): void {
    this.resetTimer2();
    this.currentIndex2 = slideIndex;
  }
  
  getCurrentSlideUrl2() {
    return `url('${this.slides2[this.currentIndex2]}')`;
  }
}
