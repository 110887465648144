<div class="container">
  <button mat-icon-button class="filter-icon" (click)="toggleFilterSidebar()" >
    <mat-icon style="color: #004aad;">filter_list</mat-icon>
  </button>
  <div class="filter-sidebar" [class.open]="isFilterSidebarOpen">
    <!-- Your existing filter sidebar content -->
    <div class="row">
      <div class="col-7">
        <button mat-button style="color:#004aad; font-size: 18px;" (click)="applyFilter()">Apply Filters</button>
      </div>
      <div class="col-5">
        <button mat-button style="color:#004aad; font-size: 18px;" (click)="clearSelection()">Clear All</button>
      </div>
    </div>
    <div class="selected-filters-container">
      <div *ngFor="let filter of userfilter" class="selected-filter">
        {{ filter }}
        <!-- <mat-icon class="remove-icon" (click)="removeFilter(filter)">cancel</mat-icon> -->
      </div>
    </div>

    <div>
      <mat-expansion-panel class="no-box-shadow" [expanded]="isExpanded">
        <mat-expansion-panel-header>
          <mat-panel-title style="font-size: 18px;">Budget</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-6">
            <div>
              <mat-form-field>
                <mat-label>Min Budget</mat-label>
                <mat-select [(value)]="selectedMinValue" (selectionChange)="onBudgetChange()">
                  <mat-option *ngFor="let option of budgetOptions" [value]="option.value">
                    {{ option.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="col-6">
            <div>
              <mat-form-field>
                <mat-label>Max Budget</mat-label>
                <mat-select [(value)]="selectedMaxValue" (selectionChange)="onBudgetChange()">
                  <mat-option *ngFor="let option of budgetOptions" [value]="option.value">
                    {{ option.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    <div>
      <mat-expansion-panel class="no-box-shadow" [expanded]="isExpanded">
        <mat-expansion-panel-header>
          <mat-panel-title style="font-size: 18px;">Profession</mat-panel-title>
        </mat-expansion-panel-header>
    
        <mat-form-field style="width: 100%; margin-bottom: 10px;">
          <input matInput placeholder="Search Profession" [(ngModel)]="searchProfessionTerm">
          <button mat-icon-button *ngIf="searchProfessionTerm" matSuffix (click)="clearSearch()">
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>
    
        <div class="scroll-container">
          <mat-radio-group [(ngModel)]="selectedProfession" (change)="selectProfession($event.value)">
            <mat-radio-button *ngFor="let profession of filterProfessions()" [value]="profession" style="display: block;">
              <div style="font-size: 14px;">{{ profession.name }}</div> <!-- Display id for debugging -->
            </mat-radio-button>
          </mat-radio-group>
        </div>
        
      </mat-expansion-panel>
    </div>
    
    
<!--     
    <div>
      <mat-expansion-panel class="no-box-shadow" [expanded]="isExpanded">
        <mat-expansion-panel-header>
          <mat-panel-title style="font-size: 18px;">Expertise</mat-panel-title>
        </mat-expansion-panel-header> -->
        <!-- Search bar for expertise -->
        <!-- <mat-form-field  style="width: 100%; margin-bottom: 10px;">
          <input matInput placeholder="Search expertise" [(ngModel)]="searchexpertiseTerm">
        </mat-form-field>
        <mat-radio-group [(ngModel)]="selectedexpertise" (change)="selectexpertise($event.value)">
          <mat-radio-button *ngFor="let expertise of filterexpertise()" [value]="expertise.name" style="display: block;">
            <div style="font-size: 14px;">{{ expertise.name }}</div>
          </mat-radio-button>
        </mat-radio-group>
      </mat-expansion-panel>
    </div> -->

    <div>
      <mat-expansion-panel class="no-box-shadow" [expanded]="isExpanded">
        <mat-expansion-panel-header>
          <mat-panel-title style="font-size: 18px;">Expertise</mat-panel-title>
        </mat-expansion-panel-header>
    
        <!-- Search bar for Profession -->
        <mat-form-field style="width: 100%; margin-bottom: 10px;">
          <input matInput placeholder="Search expertise" [(ngModel)]="searchexpertiseTerm">
          <button mat-icon-button *ngIf="searchProfessionTerm" matSuffix (click)="clearSearch()">
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>
    
        <!-- Wrapper for the scrollable content -->
        <div class="scroll-container">
          <mat-radio-group [(ngModel)]="selectedProfession"(change)="selectexpertise($event.value)">
            <mat-radio-button *ngFor="let expertise of filterexpertise()" [value]="expertise.name" style="display: block;">
              <div style="font-size: 14px;">{{ expertise.name }}</div>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </mat-expansion-panel>
    </div>
    
  </div>
</div>
