<div class="form-container">
  <h2>Add Lead</h2>
  <form [formGroup]="myForm" (ngSubmit)="onSubmit()" >
    <mat-form-field appearance="fill">
      <mat-label>Title</mat-label>
      <input matInput formControlName="title" />
      <mat-error *ngIf="myForm.get('title').hasError('required') && myForm.get('title').touched">
        Title is required.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Tentative Amount</mat-label>
      <input matInput formControlName="tentativeAmount" type="number" />
      <mat-error *ngIf="myForm.get('tentativeAmount').hasError('required') && myForm.get('tentativeAmount').touched">
        Tentative Amount is required.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Selling Lead Amount</mat-label>
      <input matInput formControlName="sellingLeadAmount" type="number" />
      <mat-error *ngIf="myForm.get('sellingLeadAmount').hasError('required') && myForm.get('sellingLeadAmount').touched">
        Selling Lead Amount is required.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Status</mat-label>
      <mat-select formControlName="status">
        <mat-option [value]="true">Active</mat-option>
        <mat-option [value]="false">Inactive</mat-option>
      </mat-select>
      <mat-error *ngIf="myForm.get('status').hasError('required') && myForm.get('status').touched">
        Status is required.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Address</mat-label>
      <input matInput formControlName="address" />
      <mat-error *ngIf="myForm.get('address').hasError('required') && myForm.get('address').touched">
        Address is required.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Party Detail</mat-label>
      <input matInput formControlName="partyDetail" />
      <mat-error *ngIf="myForm.get('partyDetail').hasError('required') && myForm.get('partyDetail').touched">
        Party Detail is required.
      </mat-error>
    </mat-form-field>

   
    
    <mat-form-field appearance="fill">
      <mat-label>Expired At</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="expiredAt">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-error *ngIf="myForm.get('expiredAt').hasError('required') && myForm.get('expiredAt').touched">
        Expiration date is required.
      </mat-error>
    </mat-form-field>
    
    

    <mat-form-field appearance="fill">
      <mat-label>Profession</mat-label>
      <mat-select formControlName="professionId">
        <mat-option *ngFor="let profession of professions" [value]="profession.id">
          {{ profession.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="myForm.get('professionId').hasError('required') && myForm.get('professionId').touched">
        Profession is required.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Expertise</mat-label>
      <mat-select formControlName="expertiseId">
        <mat-option *ngFor="let expertise of expertises" [value]="expertise.id">
          {{ expertise.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="myForm.get('expertiseId').hasError('required') && myForm.get('expertiseId').touched">
        Expertise is required.
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description"></textarea>
      <mat-error *ngIf="myForm.get('description').hasError('required') && myForm.get('description').touched">
        Description is required.
      </mat-error>
    </mat-form-field>
    <!-- display: flex; margin-left: 287px; -->
    <div class="button-container">
      <button mat-raised-button color="primary" type="submit">Submit</button>
    </div>
  </form>
</div>