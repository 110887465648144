import { Component, Input, OnInit } from '@angular/core';
import { HomeService } from '../_services/home.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyOwnerComponent } from '../property-owner/property-owner.component';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { environmentbasic } from '../../environments/environment-basic';
import { VerifieduserComponent } from '../verifieduser/verifieduser.component';
import { VerifiedotpComponent } from '../verifiedotp/verifiedotp.component';
@Component({
  selector: 'app-property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.scss'],
})
export class PropertyCardComponent implements OnInit {
  @Input() listing: any;
  @Input() editButton: boolean;
  data: any;
  id: number;
  isButtonEnabled: boolean = false;
  Images: string[] = [];
  isLogedIn:any;
  constructor(
    private dialog: MatDialog,
    private homeService: HomeService,
    private router: Router,
    private route: ActivatedRoute
  ) {
   
  }

  @Input() payload: any;

  get pricePerSqft(): number {
    const asking = Number(this.listing.asking);
    const sizeString = this.listing.size || '0';
    const size = parseFloat(sizeString.replace(/[^0-9.]/g, ''));
    return size > 0 ? asking / size : 0;
  }
  showMore: boolean = false;

  toggleShowMore(): void {
    this.showMore = !this.showMore;
  }
  ngOnInit(): void {
    
     this.isLogedIn = localStorage.getItem('auth-token');

    if (this.listing && this.listing.mediaGroup) {
      this.Images = this.listing.mediaGroup.map((item) => item.url);
      
    }
  }
  navigateToDetail(id: number): void {
      this.router.navigate(['/Leads-deatils'], { queryParams: { id: id } });
  }

  toggleStatus(id: number) {
    const newStatus = this.listing.status === 'inactive' ? 'active' :
                      this.listing.status === 'active' ? 'inactive' : 'active';
  
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to change the status to ${newStatus.charAt(0).toUpperCase() + newStatus.slice(1)}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, change it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.isConfirmed) {
        this.updateStatus(id, newStatus);
      }
    });
  }
  
  updateStatus(id: number, updatedStatus: string) {
    console.log();
    this.homeService.Updatestatus(id, updatedStatus).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        Swal.fire({
          icon: 'success',
          title: `Status ${updatedStatus.charAt(0).toUpperCase() + updatedStatus.slice(1)}`,
          showConfirmButton: false,
          timer: environmentbasic.timer
        });
        console.log('response:', response);
        this.listing.status = updatedStatus;
      } else {
        console.error(`Failed to update status to ${updatedStatus}:`, response);
      }
    });
  }
  
  editDetail(id: number){
    this.router.navigate(['/add-lead'], { queryParams: { id: id } });
  }
  verifieduser(){
    this.dialog.open(VerifieduserComponent, { 
      minWidth: '27%',
      height:'auto',
      width:'auto',
      panelClass: 'custom-dialog-container'
    });
  }
  verifiedotp(){
    this.dialog.open(VerifiedotpComponent, { 
      minWidth: '27%',
      height:'auto',
      width:'auto',
      panelClass: 'custom-dialog-container'
    });
  }

}
