import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '../_services/profile.service';
@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent{
	profilePic: string = '../assets/images/profile.png';
	updateform: FormGroup;
	submitted = false;
	isreadonly:boolean=false
	constructor(private formBuilder: FormBuilder, private profileService: ProfileService) {}
  
	ngOnInit(): void {
	  this.initializeForm();
	  this.populateForm();
	}
  
	initializeForm() {
	  this.updateform = this.formBuilder.group({
		fullName: ['', [Validators.required]],
		email: ['', [Validators.required, Validators.email]],
		mobileNumber: ['', [Validators.required]],
	  });
	}
  
	populateForm() {
	  const profileData = localStorage.getItem('auth-user');
	  
	  if (profileData) {
		const parsedProfileData = JSON.parse(profileData);
		const parsedProfileData1 = JSON.parse(parsedProfileData);
  
		console.log(parsedProfileData1);
		
		this.updateform.patchValue({
		  fullName: parsedProfileData1.fullName,
		  email: parsedProfileData1.email,
		  mobileNumber: parsedProfileData1.mobileNumber,
		});
	  }
	}
  
	get f() { return this.updateform.controls; }
  
	onSubmit() {
	  this.submitted = true;
  
	  if (this.updateform.invalid) {
		return;
	  }
  
	  const payload = this.updateform.value;
	  console.log("payload", payload);
	  localStorage.setItem('profileData', JSON.stringify(payload));
	}
  


  }
  
