<div class="form-container">
  <h2 style="margin-left: 36px;" *ngIf="!id">Add Lead</h2>
  <h2 style="margin-left: 36px;" *ngIf="id">Update Lead</h2>
  <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
    <mat-form-field class="custom-form-field">
      <mat-label>Profession</mat-label>
      <mat-select formControlName="professionId" (selectionChange)="onProfessionSelect($event)">
        <mat-option *ngFor="let profession of professions" [value]="profession.id">
          {{ profession.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="myForm.get('professionId').hasError('required') && myForm.get('professionId').touched">
        Profession is required.
      </mat-error>
    </mat-form-field>
    <mat-form-field class="custom-form-field">
      <mat-label>Expertise</mat-label>
      <mat-select formControlName="expertiseId">
        <mat-option *ngFor="let expertise of expertises" [value]="expertise.id">
          {{ expertise.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="myForm.get('expertiseId').hasError('required') && myForm.get('expertiseId').touched">
        Expertise is required.
      </mat-error>
    </mat-form-field>
    <mat-form-field class="custom-form-field" *ngIf="id">
      <mat-label>Status</mat-label>
      <mat-select formControlName="status">
        <mat-option value="active">Active</mat-option>
        <mat-option value="inactive">Inactive</mat-option>
        <mat-option value="draft">Draft</mat-option>
      </mat-select>
      <mat-error *ngIf="myForm.get('status').hasError('required') && myForm.get('status').touched">
        Status is required.
      </mat-error>
    </mat-form-field>

    <mat-form-field class="custom-form-field" *ngIf="id">
      <mat-label>Amount</mat-label>
      <input matInput formControlName="amount" type="number" (blur)="openAmountDialog()" />
      <mat-error *ngIf="myForm.get('amount').hasError('required') && myForm.get('amount').touched">
        Amount is required.
      </mat-error>
    </mat-form-field>

    <mat-form-field class="custom-form-field">
      <mat-label>ExpiredAt</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="expiredAt">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="custom-form-field">
      <mat-label>Address</mat-label>
      <input matInput formControlName="address" />
      <mat-error *ngIf="myForm.get('address').hasError('required') && myForm.get('address').touched">
        Address is required.
      </mat-error>
    </mat-form-field>
    <mat-form-field class="custom-form-field">
      <mat-label>City</mat-label>
      <mat-select formControlName="cityId" (selectionChange)="onCityChange($event)">
        <mat-option *ngFor="let city of cityDataList" [value]="city.cityId">
          {{ city.cityName }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="myForm.get('cityId').hasError('required') && myForm.get('cityId').touched">
        City is required.
      </mat-error>
    </mat-form-field>
    <mat-form-field class="custom-form-field">
      <mat-label>State</mat-label>
      <mat-select formControlName="stateId">
        <mat-option *ngIf="stateId" [value]="stateId">
          {{ stateName }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="myForm.get('stateId').hasError('required') && myForm.get('stateId').touched">
        State is required.
      </mat-error>
    </mat-form-field>
    <div class="row col-md-12">
      <mat-form-field class="custom-form-field4">
        <mat-label>Title</mat-label>
        <input matInput formControlName="title" />
        <mat-error *ngIf="myForm.get('title').hasError('required') && myForm.get('title').touched">
          Title is required.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row col-md-12">
      <div class="custom-form-field4">
        <label for="middleName" class="post-headings">Description</label>
        <textarea id="description" formControlName="description" rows="6" class="form-control"></textarea>
      </div>
    </div>
    <br>
    <h2 style="margin-left: 36px; ">Party Detail</h2>
    <mat-form-field class="custom-form-field">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
      <mat-error *ngIf="myForm.get('name').hasError('required') && myForm.get('name').touched">
        Party Detail is required.
      </mat-error>
    </mat-form-field>
    <mat-form-field class="custom-form-field">
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" />
      <mat-error *ngIf="myForm.get('email').hasError('required') && myForm.get('email').touched">
        Party Detail is required.
      </mat-error>
    </mat-form-field>
    <mat-form-field class="custom-form-field">
      <mat-label>Mobile Number</mat-label>
      <input matInput formControlName="mobileNumber" />
      <mat-error *ngIf="myForm.get('mobileNumber').hasError('required') && myForm.get('mobileNumber').touched">
        Party Detail is required.
      </mat-error>
    </mat-form-field>
    <div class="" style="margin-top:20px ;">
      <mat-form-field class="custom-form-field">
        <mat-label>Party Address</mat-label>
        <input matInput formControlName="partyAddress" />
        <mat-error *ngIf="myForm.get('partyAddress').hasError('required') && myForm.get('partyAddress').touched">
          Party Detail is required.
        </mat-error>
      </mat-form-field>
    </div>
    <mat-form-field class="custom-form-field margin-top-20">
      <div>
        <mat-toolbar>
          <input matInput [value]="selectedFileNames.length ? selectedFileNames.join(', ') : 'Select Files'" readonly />
          <div class="button-container">
            <button type="button" mat-stroked-button color="primary" (click)="triggerFileInput()">Browse</button>&nbsp;
            <button type="button" mat-flat-button color="primary" [disabled]="!selectedFiles.length"
              (click)="uploadFiles()">Upload</button>
          </div>
        </mat-toolbar>
        <input type="file" id="fileInput" formControlName="uploadPdf" (change)="handleChange($event)" name="fileInput"
          accept=".pdf,.doc,.docx" multiple hidden />
      </div>
    </mat-form-field>
    <div class="uploaded-images">
      <div *ngFor="let fileName of uploadedImages; let i = index" class="uploaded-image-container">
        <a href="#" (click)="openFile(i); $event.preventDefault();">{{ fileName }}</a>
        <button mat-icon-button style="color:#004aad ;" (click)="removeImage(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <div class="button-container">
      <button mat-raised-button color="primary" type="submit">Submit</button>
    </div>
  </form>
</div>