<div class="main-container">
  <app-header></app-header>

  <div *ngIf="loading$ | async" class="loader-overlay">
    <div class="spinner"></div>
  </div>

  <div class="content">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>
</div>
