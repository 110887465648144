<div class="container">
  <div class="row align-items-center">
    <div class="col-8">
      <h2>Party Details</h2>
    </div>
    <div class="col-4 text-right d-flex justify-content-end">
      <button class="close-button btn btn-outline-secondary" (click)="closeDialog()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <p><strong>Name:</strong> {{ listingData.partyDetail.name }}</p>
      <p><strong>Mobile Number:</strong> {{ listingData.partyDetail.mobileNumber }}</p>
      <p><strong>Email:</strong> {{ listingData.partyDetail.email }}</p>
      <p><strong>Address:</strong> {{ listingData.partyDetail.address }}</p>
    </div>
  </div>
</div>
