import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PrivacyPolicyComponent } from '../Footer-content/privacy-policy/privacy-policy.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VerifiedotpComponent } from '../verifiedotp/verifiedotp.component';
import { HomeService } from '../_services/home.service';
import { LoadingService } from '../_services/loading.service';

@Component({
  selector: 'app-verifieduser',
  templateUrl: './verifieduser.component.html',
  styleUrls: ['./verifieduser.component.scss']
})
export class VerifieduserComponent implements OnInit {
  name: string;
  icon: string;
  expertiseId:string
  professionId:string
  form: FormGroup;
  submitted = false;

  constructor(private fb: FormBuilder,
              private rtr: Router,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: { expertiseId: string, professionId: string, icon: string, name: string },
              private homeService: HomeService,
              private loadingService: LoadingService,
              public dialogRef: MatDialogRef<PrivacyPolicyComponent>) {
                this.expertiseId = data.expertiseId;
                this.professionId = data.professionId;
                this.name = data.name;
                this.icon = data.icon;

    console.log(this.icon);
    
    this.form = this.fb.group({
      mobileNumber: ['', [Validators.required]]
    });
    console.log('Expertise ID:',this.expertiseId);
    console.log('Profession ID:', this.professionId);
  }

  ngOnInit(): void {}

  onSubmit() {
    const mobileNumber = this.form.get('mobileNumber')?.value;  
    this.homeService.sendotpToMobile(mobileNumber).subscribe(
      (res: any) => {
        console.log(res);
  
        if (res && res.status === 'SUCCESS') {
          this.skip();
          this.verifiedotp(mobileNumber, this.name, this.icon);  
        } else {
          console.error('Unexpected response:', res);
          this.verifiedotp(mobileNumber, this.name, this.icon); 
        }
      },
      error => {
        console.error('Error sending email OTP:', error);
      }
    );
  }

  policyanavigate() {
    this.dialogRef.close();
    this.rtr.navigate(['./privacyPolicy']);
  }

  skip() {
    this.dialogRef.close();
  }

  verifiedotp(mobileNumber: string, name: string, icon: string) {
    this.dialog.open(VerifiedotpComponent, { 
      minWidth: '27%',
      maxHeight: '100%',
      height: 'auto',
      width: 'auto',
      panelClass: 'custom-dialog-container',
      data: { 
        mobileNumber, 
        expertiseId: this.data.expertiseId, 
        professionId: this.data.professionId,
        name,
        icon   
      }  
    });
  }
}
