
<div class="container-fluid">
  <div class="contain" id="contain">
    <div class="form-contain sign-in-contain w-100">
      <form  [formGroup]="resetform" (ngSubmit)="onResetSubmit()" class="account">
        <h3></h3>
        <input type="password" placeholder="Current Password" formControlName="resetpassword"/>
		<div class="errorClass" *ngIf="submitted && l.resetpassword.errors">
			<div *ngIf="l.resetpassword.errors.required">Password is required</div>
			<div *ngIf="l.resetpassword.errors.minlength">Password must be at least 6 characters</div>
		</div>
		<input type="password" placeholder="New Password" formControlName="resetpasswordconfirm"/>
		<div class="errorClass" *ngIf="submitted && l.resetpasswordconfirm.errors">
			<div *ngIf="l.resetpasswordconfirm.errors.required">Password is required</div>
			<div *ngIf="l.resetpasswordconfirm.errors.minlength">Password must be at least 6 characters</div>
		</div>
        <button class="signinn mb-2">Reset Password</button>
        <a routerLink="/login" >Login</a>
      </form>
    </div>
    
  </div>
</div>