import { Component, HostListener, OnInit } from '@angular/core';
import { HomeService } from '../_services/home.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../_services/loading.service';

@Component({
  selector: 'app-user-leads',
  templateUrl: './user-leads.component.html',
  styleUrls: ['./user-leads.component.scss']
})
export class UserLeadsComponent implements OnInit {

  listings:any;
  Images: string[] = [];
  showTopIcon = false; 
  constructor(
    private homeService: HomeService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
  ) { }
  ngOnInit(): void {
    this.getlaedbyUser()
    
  }
  @HostListener('window:scroll', [])
    onWindowScroll() {
      this.showTopIcon = window.scrollY > 100; // Show icon if scrolled down more than 200px
    }
  
    scrollToTop(): void {
        
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  showMore: boolean = false;
  toggleShowMore(): void {
    this.showMore = !this.showMore;
  }
  getlaedbyUser() {
    // this.homeService.getProprtybyUser().subscribe(
    //   (response: any) => {
    //     if (response.status === 'SUCCESS') {
    //       this.listings=response?.payload;
    //       if (this.listing && this.listing.mediaGroup) {
    //         this.Images = this.listing.mediaGroup.map(media => media.url);
    //         console.log('images',this.Images);
    //       } else {
    //         this.Images = [];
    //       }
          
          
    //       console.log('listing',this.listing);
    //     } else {
    //       console.error('Failed to fetch city data:', response);
    //     }
    //   },
    //   (error) => {
    //     console.error('Error fetching city data:', error);
    //   }
    // );
    this.homeService.getleadbyUser().subscribe(
			(response: any) => {
				if (response && response.payload) {
                    this.loadingService.hide();
				           	this.listings = response.payload;
					console.log('Listings:', this.listings,length);
				} else {
					console.error('Unexpected data format:', response);
					this.listings = [];
                  
				}
			},
			error => {
                this.loadingService.hide();
				console.error('Error loading listings:', error);
				this.listings = [];
			}
		);
  }
}