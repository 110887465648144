<div class="row justify-content-end align-items-end custom-row">
  <button type="button" class="close" (click)="skip()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="row d-flex justify-content-center align-items-center mt-2">
    <img src="../../assets/images/logo-no-background.png" alt="" style="width: 45px;">
  </div><br>
  <div class="row d-flex justify-content-center align-items-center">
    <span class="letsingn">Please verify you are human </span>
  </div>
  <div class="row  d-flex justify-content-center align-items-center p-3" style="margin-top: 20px;">
    <div class="col-12">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <input
          type="text"
          placeholder="Enter Email"
          formControlName="mobileNumber"
          class="styled-input"
         
        />
        <div class="errorClass" *ngIf="submitted && form.controls['mobileNumber'].errors">
          <div *ngIf="form.controls['mobileNumber'].errors.required">Mobile Number & Eamil is required</div>
          <div *ngIf="form.controls['mobileNumber'].errors.pattern">Mobile Number & Eamil must be valid</div>
        </div>
        <div class="row  d-flex justify-content-center align-items-center mt-2">
        <button type="submit" class="styled-button mt-3">Send OTP</button></div>
      </form>
    </div>
  </div>
  <div class="row d-flex justify-content-center align-items-center">
    <span class="footerpolicy">
      By signing in, you agree to the 
      <span style="text-decoration: underline; color:black; cursor: pointer;" (click)="policyanavigate()">Terms and Policy</span>
    </span>
  </div>
 
  
  
  