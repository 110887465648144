import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { HomeService } from '../_services/home.service';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-add-property',
  templateUrl: './add-property.component.html',
  styleUrls: ['./add-property.component.scss'],
})
export class AddPropertyComponent implements OnInit {
  myForm: FormGroup;
  professions = []; // Populate this array with profession data
  expertises = []; // Populate this array with expertise data

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.myForm = this.fb.group({
      title: ['', Validators.required],
      tentativeAmount: ['', Validators.required],
      sellingLeadAmount: ['', Validators.required],
      status: ['', Validators.required],
      address: ['', Validators.required],
      partyDetail: ['', Validators.required],
      expiredAt: ['', Validators.required],
      professionId: ['', Validators.required],
      expertiseId: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  onSubmit(): void {
    if (this.myForm.valid) {
      console.log(this.myForm.value);
    } else {
      this.myForm.markAllAsTouched();
    }
  }
}
