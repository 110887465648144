import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HomeService } from '../_services/home.service';
import { LoadingService } from '../_services/loading.service';
import { VerifieduserComponent } from '../verifieduser/verifieduser.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-experties-popup',
  templateUrl: './experties-popup.component.html',
  styleUrls: ['./experties-popup.component.scss']
})
export class ExpertiesPopupComponent implements OnInit {
  experties = [];
  professions=[];
  pId: string;
  currentIndex: number = 0;
  currentIndex1: number = 0;
  slides1 = [
     'https://w0.peakpx.com/wallpaper/163/36/HD-wallpaper-white-ganpati-statue-in-blur-background-ganesh.jpg',
      'https://thumbs.dreamstime.com/b/medical-tech-science-innovative-iot-global-healthcare-ai-technology-doctor-telehealth-telemedicine-service-medical-tech-210434735.jpg',
      'https://www.shutterstock.com/image-photo/medical-technology-doctor-use-ai-260nw-2353590953.jpg',
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEHyeuE7atThbIyuqzQ4k6mXUiIsOW8LNg_g&s',
      'https://www.shutterstock.com/image-vector/agile-development-optimisation-concept-software-260nw-2355465819.jpg'
    ];
    currentIndex2: number = 0;
    slides2 = [
      'https://www.shutterstock.com/image-vector/agile-development-optimisation-concept-software-260nw-2355465819.jpg',
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEHyeuE7atThbIyuqzQ4k6mXUiIsOW8LNg_g&s',
      'https://thumbs.dreamstime.com/b/medical-tech-science-innovative-iot-global-healthcare-ai-technology-doctor-telehealth-telemedicine-service-medical-tech-210434735.jpg',
      'https://www.shutterstock.com/image-photo/medical-technology-doctor-use-ai-260nw-2353590953.jpg',
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQWkb_3HLznguhvVdiwECMjh60QmHEIHvWdA&s'
     ];
  constructor(
    private homeService: HomeService,
    private route: ActivatedRoute,
    private dialog:MatDialog,
    private loadingService: LoadingService,
    // @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.searchSubject.pipe(
      debounceTime(300) 
    ).subscribe(searchText => {
      this.searchExpertise(searchText);
    });
   }

  ngOnInit(): void {
    this.getExperties();
    if (this.slides1.length === 0) {
      console.warn('No slides available.');
    }
    if (this.slides2.length === 0) {
      console.warn('No slides available.');
    }

    this.resetTimer1();
    this.resetTimer2();

  }
  searchExpertise(searchText: string) {
    this.homeService.getExpertise(searchText).subscribe(
      (response: any) => {
        if (response && response.payload ) {
          this.loadingService.hide();
          this.experties = response.payload; 
          
        } else {
          console.error('Unexpected data format:', response);
          this.experties = [];
        }
      },
      error => {
        this.loadingService.hide();
        console.error('Error loading listings:', error);
        this.experties = [];
      }
    );
  }
  searchSubject = new Subject<string>();
  onSearch(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    const searchText = inputElement.value;
    console.log("searchText",searchText)
    this.searchSubject.next(searchText);
  }

  getExperties() {
    this.pId = this.route.snapshot.queryParamMap.get('id');
    this.homeService.getExperties(this.pId).subscribe(
      (response: any) => {
        if (response && response.payload) {
          this.loadingService.hide();
          this.experties = response.payload.map((item: any) => ({
            id: item.id,
            name: item.name,
            icon: item.icon,
          }));
          console.log("Experties data:", this.experties);
        } else {
          console.error('Unexpected data format:', response);
          this.experties = [];
        }
      },
      error => {
        this.loadingService.hide();
        console.error('Error loading experties:', error);
        this.experties = [];
      }
    );
  }
  verifieduser(expertiseId: string, professionId: string) {
    this.dialog.open(VerifieduserComponent, { 
      minWidth: '27%',
      height: 'auto',
      width: 'auto',
      panelClass: 'custom-dialog-container',
      data: { expertiseId, professionId } 
    });
  }

  resetTimer1() {
    if (this.timeoutId1) {
      window.clearTimeout(this.timeoutId1);
    }
    this.timeoutId1 = window.setTimeout(() => this.goToNext1(), 4000);
  }
  
  goToPrevious1(): void {
    const isFirstSlide = this.currentIndex1 === 0;
    const newIndex = isFirstSlide ? this.slides1.length - 1 : this.currentIndex1 - 1;
  
    this.resetTimer1();
    this.currentIndex1 = newIndex;
  }
  
  goToNext1(): void {
    const isLastSlide = this.currentIndex1 === this.slides1.length - 1;
    const newIndex = isLastSlide ? 0 : this.currentIndex1 + 1;
  
    this.resetTimer1();
    this.currentIndex1 = newIndex;
  }
  
  goToSlide1(slideIndex: number): void {
    this.resetTimer1();
    this.currentIndex1 = slideIndex;
  }
  
  getCurrentSlideUrl1() {
    return `url('${this.slides1[this.currentIndex1]}')`;
  }
  timeoutId1: any;
timeoutId2: any;
  resetTimer2() {
    if (this.timeoutId2) {
      window.clearTimeout(this.timeoutId2);
    }
    this.timeoutId2 = window.setTimeout(() => this.goToNext2(), 4000);
  }
  
  goToPrevious2(): void {
    const isFirstSlide = this.currentIndex2 === 0;
    const newIndex = isFirstSlide ? this.slides2.length - 1 : this.currentIndex2 - 1;
  
    this.resetTimer2();
    this.currentIndex2 = newIndex;
  }
  
  goToNext2(): void {
    const isLastSlide = this.currentIndex2 === this.slides2.length - 1;
    const newIndex = isLastSlide ? 0 : this.currentIndex2 + 1;
  
    this.resetTimer2();
    this.currentIndex2 = newIndex;
  }
  
  goToSlide2(slideIndex: number): void {
    this.resetTimer2();
    this.currentIndex2 = slideIndex;
  }
  
  getCurrentSlideUrl2() {
    return `url('${this.slides2[this.currentIndex2]}')`;
  }

}
