<header class="header_p">
	<div class="navigation-wrap bg-light stlatest-header stlatest-style">
		<div class="container-fluid mob-menu-width">
			<div class="row" style="margin-top: 5px; box-shadow: 0 1px 6px rgba(57, 63, 72, 0.3);">
				<div class="col-12 col-md-12 col-sm-12">
					<nav class="navbar navbar-expand-xl navbar-light">
						<button class="navbar-toggler" type="button" #navbarToggler1 data-toggle="collapse"
							data-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1"
							aria-expanded="false" aria-label="Toggle navigation">
						</button>
						<a class="navbar-brand mr-0" routerLink="/">
							<img src="../../assets/images/logo-no-background.png" alt="" style="width: 55px;">
						</a>
						<button class="navbar-toggler" type="button" #navbarToggler data-toggle="collapse"
							data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
							aria-expanded="false" aria-label="Toggle navigation">
							<div>
								<!-- <img alt="" src="../../assets/images/menu-profile-icon.png" style="height: 50px;"> -->
								<i class="fa fa-filter" aria-hidden="true" style="color: #004aad;"></i>
							</div>
						</button>

						<section class="navup" data-aos-delay="500">
							<div class="collapse navbar-collapse" id="navbarSupportedContent1" style="width: 100%;"
								[ngClass]="{'collapse': collapsed, 'navbar-collapse': true}">
								
							</div>
						</section>
						<div class="collapse navbar-collapse" id="navbarSupportedContent" style="width:100%"
						[ngClass]="{'collapse': collapsed1, 'navbar-collapse': true}">
						<ul class="navbar-nav ml-auto p-0 right-header-content ">
							<!-- <li class="nav-item d-flex justify-content-center flex-grow-1">
								<div class="searchbar d-flex align-items-center">
									<input class="search_input form-control" type="text" #searchkey
									placeholder="Search Lead" (keyup.enter)="searchArt()" />
								  <a href="javascript:;" (click)="searchArt()" class="search_icon">
									<i class="fa fa-search" aria-hidden="true" style="color: #000;"></i>
								  </a>
								</div>
							</li> -->
							<li class="nav-item" *ngIf="userinfo && userinfo.email; else notlogin">
								<span class="p-2" style="font-size: 20px; padding-right: 30px!important ;">
									<a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
									   aria-expanded="false" style="font-weight: bold;">
									  <span *ngIf="userName; else showFullName" style="font-weight: bold;">{{userName}}</span>&nbsp;&nbsp;
									  <ng-template #showFullName>
										<span style="font-weight: bold;">{{userfullname}}</span>
									  </ng-template>
									  <a routerLink="/add-lead" class="add-property-link">Add Lead</a>
									  <button mat-icon-button [matMenuTriggerFor]="menu">
										<i class="fa fa-user-circle" style="color: #004aad; font-size: 34px;"></i>
									  </button>
									</a>
								  </span>
								  
								<mat-menu #menu="matMenu">
									<button mat-menu-item routerLink="update/profile/username/userid/activetab">
										<i class="fas fa-user" style="color: #004aad; font-size: 16px;"></i>
										<span>&nbsp; My Profile</span>
									</button>
									<button mat-menu-item (click)="navigateToUserLead()">
										<i class="fa fa-building" style="color: #004aad; font-size: 16px;"></i>
										&nbsp; My Leads
									</button>
									<!-- <button mat-menu-item routerLink="/update/profile/username/userid/activetab">
										
										<i class="fa fa-users" style="color: #004aad; font-size: 16px;"></i>
										<span>&nbsp; Update Profile</span>
									</button> -->
									<button mat-menu-item (click)="userLogout()">
										<i class="fas fa-sign-out-alt" style="color: #004aad; font-size: 16px;"></i>
										&nbsp; Logout
									</button>

									<!-- <button mat-menu-item (click)="navigateToUserProprty()">
										<i class="fa fa-building-o" style="color: #004aad; font-size: 16px;"></i>
										&nbsp; My Property
									</button> -->
								</mat-menu>
							</li>
							<ng-template #notlogin>
								<li class="nav-item">
								  <div class="nav-content">
									<a routerLink="/login" class="add-property-link" style="width: 80px;">Add Lead</a>
									<a routerLink="/login" class="login-signup-link">Login | Signup</a>
								  </div>
								</li>
							  </ng-template>
						</ul>
					  </div>
					</nav>
				</div>
			</div>
		</div>
	</div>
</header>
