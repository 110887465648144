<app-menu></app-menu>
<section class="sponser mt-3">
  <div class="container">
    <form [formGroup]="jobDetailForm" (ngSubmit)="onJobDetailSubmit(true)">
      <div class="account_card">

        <div class="form-row">
          <div class="form-group col-md-12">
            <label for="firstName" class="post-headings">Title</label>
            <input type="text" class="form-control" id="title" formControlName="title" placeholder="Title"
              maxlength="43">
          </div>
          <div class="errorClass" *ngIf="submitted && f.title.errors">
            <div *ngIf="f.title.errors.required">Title is required</div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label for="city" class="post-headings">Banner</label>
            <span class="btn float-right btn-file custom_choose_one"><i class="fas fa-paperclip" aria-hidden="true"></i>
              <input type="file" id="banner" accept="image/*" (change)="uploadBanner($event,'jobBanner');">
            </span>
            <input type="hidden" class="form-control" id="banner" formControlName="banner" placeholder="Max">
            <img src="{{bannerName}}" style="width: 100%;" class="d-block img-fluid">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <label for="middleName" class="post-headings">Description</label>
            <editor id="description" formControlName="description"
              apiKey="lgj4xevo9zhqe2g8x7h14rbgxqdl47dt7aw63xw6o1fwfejh" [init]="{
                  height: 300,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help'
                }">
            </editor>

          </div>

        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="country" class="post-headings">Expected Candidate Availability</label>
            <select class="form-control" id="expectedCandidateAvailability"
              placeholder="Expected Candidate Availability" formControlName="expectedCandidateAvailability">
              <option value="Part Time">Part Time</option>
              <option value="Full Time">Full Time</option>
              <option value="No restriction need only work done">No restriction need only work done</option>
            </select>

          </div>
          <div class="form-group col-md-6">
            <label for="languageSecondary" class="post-headings">Expert Level</label>
            <select formControlName="expertLevel" class="form-control" id="expertLevel" placeholder="Expert Level">
              <option value="Beginner">Beginner</option>
              <option value="Intermediate">Intermediate</option>
              <option value="Expert">Expert</option>
            </select>

          </div>
          <div class="form-group col-sm-6">
            <label for="estimatedMinBudget" class="post-headings col-sm-12 p-0">Estimated Budget</label>
            <div class="form-group col-sm-4  float-left pl-0 minBudget"> <input type="number" class="form-control"
                id="estimatedMinBudget" formControlName="estimatedMinBudget" placeholder="Min"></div>

            <div class="form-group col-sm-4 float-left pl-1 pr-1 maxBudget"> <input type="number" class="form-control"
                id="estimatedMaxBudget" formControlName="estimatedMaxBudget" placeholder="Max"></div>
            <div class="form-group col-sm-4  float-left  pr-0 currency">
              <select formControlName="currency" class="form-control" id="currency" placeholder="Currency">
                <option value="">Select Currency</option>
                <option value="INR">INR</option>
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="AED">AED</option>
              </select>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="gender" class="post-headings">Estimated Project Length</label>
            <select formControlName="Gender" class="form-control" formControlName="estimatedProjectLength"
              id="estimatedProjectLength" placeholder="Project Length">
              <option value="Less than one week">Less than one week</option>
              <option value="Less than one month">Less than one month</option>
              <option value="Less than three month">Less than three month</option>
              <option value="Less than six month">Less than six month</option>
              <option value="More than six month">More than six month</option>
            </select>

          </div>

          <div class="form-group col-md-6 post-assignment-skills">
            <label for="lastName" class="post-headings">Skills</label>
            <ng-multiselect-dropdown [placeholder]="'Required Skills'" [settings]="dropdownSettings" [data]="skills"
              formControlName="selectedSkills" [(ngModel)]="selectedSkills" (onSelect)="updateUserSkills($event)"
              (onDeSelect)="removeUserSkills($event)">
            </ng-multiselect-dropdown>


          </div>
          <!--div class="form-group col-md-6">
            <label for="state"><b>Project Type</b></label>
            <select class="form-control" id="projectType" placeholder="Project Type" formControlName="projectType">
              <option value="simple">Straight Forward</option>
              <option value="complex">Complex Business Flow</option>
              <option value="veryComplex">Very Complex Business Flow</option>
              <option value="rNd">Research and Development work</option>
            </select>
          </div-->
          <div class="form-group col-md-6 post-assignment-skills">
            <label for="city" class="post-headings">Preferred Country</label>
            <ng-multiselect-dropdown [placeholder]="'Prefer Country'" [settings]="dropdownSettingsCountry"
              formControlName="preferCountries" [data]="countries" [(ngModel)]="preferCountries"
              (onSelect)="updateCountry($event)" (onDeSelect)="removeCountry($event)">
            </ng-multiselect-dropdown>

          </div>
          <div class="form-group col-sm-6">
            <label for="city" class="post-headings">Expire Date*</label>
            <input type="date" class="form-control" id="expiredate" formControlName="expireDate"
              placeholder="dd-MM-yyyy" value="">
            <div class="errorClass" *ngIf="submitted && f.expireDate.errors">
              <div *ngIf="f.expireDate.errors.required">Date is required</div>
            </div>

          </div>
          <div class="form-group col-md-6">
            <label for="city" class="post-headings">Supportive Documents</label>
            <span class="btn float-right btn-file custom_choose_one"><i class="fas fa-paperclip" aria-hidden="true"></i>
              <input type="file" id="supportiveDocuments" multiple="multiple"
                (change)="uploadFile($event,'jobSupportedDocument');">
            </span>
            <input type="hidden" class="form-control" id="supportiveDocumentList"
              formControlName="supportiveDocumentList" placeholder="Max">
          </div>

          <div *ngIf="supportiveDocumentList!=''" class="form-group col-sm-12">
            <div class="table-responsive w-100">
              <table class="table table-responsive">
                <tbody>
                  <tr *ngFor="let link of supportiveDocumentListArray; let i = index">
                    <td>{{i+1}}</td>
                    <td><a target="_blank" href={{link}}> {{link.substring(link.lastIndexOf('/')+1)}}</a></td>
                  </tr>
                  <!--<tr *ngFor="let recipient of url_map | keyvalue; let i = index">
                    <td>{{i+1}}</td>
                    <td><a href={{recipient.value}}>{{recipient.key}}</a></td>
                  </tr> -->
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
      <button type="submit" class="btn btn-danger" style="margin-left: 20px;">Update Job</button>
    </form>
  </div>
</section>