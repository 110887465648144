import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HomeService } from '../_services/home.service';
import { LoadingService } from '../_services/loading.service';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {

  selectedFiles: File[] = [];
  selectedFileNames: string[] = [];
  formGroup: FormGroup;
  uploadedImages: string[] = [];

  constructor(
    private homeService: HomeService,
    private loadingService: LoadingService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CommentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.formGroup = this.fb.group({
      comment: ['', Validators.required],
      imageUrls: [""], // This will be a string
    });
  }

  ngOnInit(): void {
    console.log('Dialog data:', this.data);
  }

  triggerFileInput(): void {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }

  uploadFiles(): void {
    if (this.selectedFiles.length) {
      const fileUploads$ = this.selectedFiles.map((selectedFile) => {
        const mimeType = selectedFile.type;
        const purpose = 'professionalDocument';

        return this.homeService.getMediaData(purpose, mimeType, selectedFile)
          .pipe(finalize(() => { this.cd.detectChanges(); }))
          .subscribe((res: any) => {
            this.uploadedImages.push(res.url);
            this.cd.detectChanges();
          });
      });

      this.clearFileInput();
    }
  }

  openFile(index: number): void {
    const fileUrl = this.uploadedImages[index];
    window.open(fileUrl, '_blank');
  }

  handleChange(event: any): void {
    const files = event.target.files;
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const validFileTypes = ['application/pdf', 'application/msword',
           'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

        if (validFileTypes.includes(file.type)) {
          this.selectedFiles.push(file);
          this.selectedFileNames.push(file.name);
          // Update formGroup value if needed (imageUrls will be updated when files are uploaded)
        } else {
          alert('Only PDF or DOC files are allowed.');
        }
      }
      this.cd.detectChanges();
    }
  }

  clearFileInput(): void {
    this.selectedFiles = [];
    this.selectedFileNames = [];
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ''; 
    }
    this.cd.detectChanges();
  }

  removeImage(index: number): void {
    this.uploadedImages.splice(index, 1);
    this.selectedFiles.splice(index, 1);
    this.selectedFileNames.splice(index, 1);
    this.cd.detectChanges();
  }

  closeModal(): void {
    this.dialogRef.close(); // Close the dialog
  }

  onSubmit(): void {
    if (this.formGroup.valid) {
      const leadId = this.data?.id;
      const comment = this.formGroup.get('comment')?.value;

      // Convert uploadedImages array to a comma-separated string
      const imageUrls = this.uploadedImages.join(',');

      // Prepare the payload
      const payload = {
        comment: comment,
        imageUrls: imageUrls // Send as a string
      };

      this.Comment(leadId, payload);
    }
  }

  Comment(leadId: any, commentData: any): void {
    this.homeService.CreateComment(leadId, commentData).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          console.log('Response:', response);
          this.dialogRef.close({ success: true });
        } else {
          console.error('Response status is not SUCCESS');
        }
      },
      (error) => {
        console.error('API call error', error);
      }
    );
  }
}
