import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PrivacyPolicyComponent } from '../Footer-content/privacy-policy/privacy-policy.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HomeService } from '../_services/home.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { AuthService } from '../_services/auth.service';
import { UserProfileService } from '../_services/user-profile.service';
import { environmentbasic } from 'src/environments/environment-basic';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-verifiedotp',
  templateUrl: './verifiedotp.component.html',
  styleUrls: ['./verifiedotp.component.scss'],
})
export class VerifiedotpComponent implements OnInit {
  otp: string = '';
  form: FormGroup;
  submitted = false;
  mobilecode = '9881873919';
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      mobileNumber: string;
      expertiseId: string;
      professionId: string;
    },
    private tokenStorage: TokenStorageService,
    private fb: FormBuilder,
    private userProfileService:UserProfileService,
    private authService: AuthService,
    private rtr: Router,
    private homeService: HomeService,
    public dialogRef: MatDialogRef<PrivacyPolicyComponent>
  ) {
    console.log('Mobile Number:', data.mobileNumber);
    console.log('Expertise ID:', data.expertiseId);
    console.log('Profession ID:', data.professionId);
    this.form = this.fb.group({
      mobileNumber: [
        '',
        [Validators.required, Validators.pattern(/^[0-9]{10}$/)],
      ],
    });
  }
  ngOnInit(): void {}

  onSubmitOtp() {}

  resend(){
   
    this.homeService.sendotpToMobile(this.data.mobileNumber).subscribe(
      (res: any) => {
        console.log(res);
  
        if (res && res.status === 'SUCCESS') {
          
        } else {
          
        }
      },
      error => {
        console.error('Error sending email OTP:', error);
      }
    );
  }
  skip(){
    this.dialogRef.close();
  }

  onOtpChange(otp: string) {
    this.otp = otp;
    console.log(otp);
  }

  UserProfile(){
    this.authService.getProfileData().subscribe(
      response => {
      if (response) {
      let UserProfile =response
      localStorage.setItem("UserProfile", JSON.stringify(UserProfile));
                  console.log("UserProfile retrieved successfully");
                  this.userProfileService.notifyUserProfileUpdate();
    } else {
      console.log("Failed to retrieve user profile");
    }

      }
    )

  
  }

  verifyOtp() {
    
    this.homeService
      .AddNewLead(
        this.data.mobileNumber,
        this.data.professionId,
        this.data.expertiseId,
        this.otp
      )
      .subscribe(
        (response) => {
          if (response['status'] === 'SUCCESS') {
            console.log(response);
            if (response && response.status === 'SUCCESS') {
              console.log('hello');
              console.log('response->', response);
              let accessT = response.payload.access_token;
              const accessToken = response.payload;
              this.tokenStorage.saveToken(JSON.stringify(accessToken));
              const refreshToken = response.payload.refresh_token;
              localStorage.setItem('access_token', accessT);
              const expiresIn = response.payload.expires_in;
              localStorage.setItem('refresh_token', refreshToken);
              localStorage.setItem('expires_in', expiresIn);
              this.tokenStorage.saveSocialOrigin(null);
              this.tokenStorage.saveSocialUserEmail(null);
              this.tokenStorage.saveFirstVisitToHomePageAfterLogin(
                'firstVisit'
              );
              this.UserProfile();
              // Use switchMap to chain the profile data retrieval
              // return this.authService.getProfileData();
              Swal.fire({
                icon: 'success',
                title: "Your service request has been received. Our team will connect with you shortly.",
              });
            } else {
              // Swal.fire({
              //   icon: 'error',
              //   title: response['error_description'],
              //   showConfirmButton: false,
              //   timer: environmentbasic.timer,
              // });
              // this.loading = false;
              throw new Error('Login failed');
            }
            this.skip();
          } else {
          }
        },
        (error) => {
          // Handle error
          console.error('Error adding new lead:', error);
        }
      );
  }
}
