import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { TokenStorageService } from './token-storage.service';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { UserProfileService } from './user-profile.service';

@Injectable({
	providedIn: 'root'
})
export class HomeService {
	token = '';
	usertoken: any = [];
	key = '';
	socialOrigin:any;
	email:any;
	Token='';
	constructor(private tokenStorage: TokenStorageService,
		private userProfileService: UserProfileService,
		private http: HttpClient) {
		this.usertoken = JSON.parse(this.tokenStorage.getToken());
		this.socialOrigin = this.tokenStorage.getSocialOrigin();
		this.email = this.tokenStorage.getSocialUserEmail();
		if (this.usertoken && this.usertoken.access_token) {
			this.token = this.usertoken.access_token;
		}
       
		this.userProfileService.getUserProfileUpdateNotifier().subscribe(() => {
			this.usertoken = JSON.parse(this.tokenStorage.getToken());
		this.socialOrigin = this.tokenStorage.getSocialOrigin();
		this.email = this.tokenStorage.getSocialUserEmail();
		if (this.usertoken && this.usertoken.access_token) {
			this.token = this.usertoken.access_token;
		}
		})
	}


	getArts(listParams) {

		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/artwork/search`, JSON.stringify(listParams), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
							icon: 'error',
							title: 'Invalid request',
							showConfirmButton: false,
							timer: 6000
						});*/
					return throwError(error);
				})
			);
	}

	getMyArts(userid) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/artwork/user?accountOwnerUserId=${userid}`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	getMyFavProjectsArts(pageNumber, limit, accountOwnerUserId) {

		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/artwork/favorite?socialOrigin=${this.socialOrigin}&email=${this.email}&pageNumber=` + pageNumber + `&limit=` + limit + `&accountOwnerUserId=` + accountOwnerUserId, JSON.stringify({}), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}
	searchExperts(listParams) {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/user/search`, JSON.stringify(listParams), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	getUsersAllActivities(accountOwnerUserId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/user/activities?socialOrigin=${this.socialOrigin}&email=${this.email}&userId=` + accountOwnerUserId, JSON.stringify({}), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	getCategories() {

		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/master/artwork/categories`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}
	
	getMediums() {

		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/master/mediums`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	getSoftwares() {

		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/master/softwares`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	getTags() {

		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/master/tags`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	saveTags(fdata) {

		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/master/tag?socialOrigin=${this.socialOrigin}&email=${this.email}`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	saveMedium(fdata) {

		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/master/medium?socialOrigin=${this.socialOrigin}&email=${this.email}`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}
	getSkills() {

		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/master/skills`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	getBackgroundContent(screenName, count) {

		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/master/customizeBackgroundContent/screen?screenName=` + screenName + `&count=` + count, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	getAllSocialPlatforms() {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/master/socialPlatforms`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	getMediaMetaData(purpose, contentType, file) {
		let key: string = null;
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});

		return this.http.get(`${environment.apiUrl}/media/presigned?socialOrigin=${this.socialOrigin}&email=${this.email}&purpose=${purpose}&contentType=${contentType}`, {
			headers: headers
		})
			.pipe(
				concatMap(
					(res) =>
						this.s3UploadCall(res['payload']['preSignedUrl'], res['payload']['key'], file, contentType)),
				concatMap(
					() => this.updateMediaInfoOnServer(this.key, purpose)));
	}

	getArtworkMetaData(purpose, contentType, file) {
		let key: string = null;
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});

		return this.http.get(`${environment.apiUrl}/media/presigned?socialOrigin=${this.socialOrigin}&email=${this.email}&purpose=${purpose}&contentType=${contentType}`, {
			headers: headers
		})
			.pipe(
				concatMap(
					(res) =>
						this.s3UploadCall(res['payload']['preSignedUrl'], res['payload']['key'], file, contentType)));
	}

	s3UploadCall(preSignedUrl, key, file, contentType) {
		this.key = key;
		const headersPic = new HttpHeaders({
			'Content-Type': contentType
		});
		return this.http.put(preSignedUrl, file, { headers: headersPic });
	}

	updateMediaInfoOnServer(key, purpose) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/user/media/update?socialOrigin=${this.socialOrigin}&email=${this.email}&profilePicKey=` + key + `&purpose=` + purpose, {}, {
			headers: headers
		})
	}

	fetchMemberDetails(searchString) {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Basic ' + this.token
		});
		return this.http.get(`${environment.apiChatUrl}/member/search?searchString=` + searchString, {
			headers: headers
		})
			.pipe(data => {
				return data;
			});
	}
	getMyChatRoom(email) {

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Basic ' + this.token
		});
		return this.http.get(`${environment.apiChatUrl}/chat/room/me?email=` + email, {
			headers: headers
		})
			.pipe(data => {
				return data;
			});
	}

	getMyUnReadMessageCount() {

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Basic ' + this.token
		});
		return this.http.get(`${environment.apiChatUrl}/chat/unread/count`, {
			headers: headers
		})
			.pipe(data => {
				return data;
			});
	}

	updateChatMessageStatus(destination){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Basic ' + this.token
		});
		return this.http.put(`${environment.apiChatUrl}/chat/read/status?uniqueIdentifier=`+destination,{}, {
			headers: headers
		})
			.pipe(data => {
				return data;
			});
	}
	removeMemberFromChatRoom(memberId, uniqueIdentifier) {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Basic ' + this.token
		});
		return this.http.put(`${environment.apiChatUrl}/chat/room/member/remove?uniqueIdentifier=` + uniqueIdentifier + `&memberId=` + memberId, {}, {
			headers: headers
		})
			.pipe(data => {
				return data;
			});
	}
	fetchDetailsInChatRoom(uniqueIdentifier, limit, pageNumber) {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Basic ' + this.token
		});
		return this.http.get(`${environment.apiChatUrl}/chat/room/details/fetch?uniqueIdentifier=` + uniqueIdentifier + `&pageNumber=` + pageNumber + `&limit=` + limit, {
			headers: headers
		})
			.pipe(data => {
				return data;
			});
	}
	saveOrEditChatRoom(fdata) {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Basic ' + this.token
		});
		return this.http.post(`${environment.apiChatUrl}/chat/room`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(data => {
				return data;
			});
	}


	// getstatebyCountryId() {
	// 	const headers = new HttpHeaders({
	// 		'Content-Type': 'application/json',
	// 		'Authorization': 'Basic ' + this.token
	// 	});
	// 	return this.http.get(`${environment.apiUrl}/state/by-country?countryId=101`, {
	// 		headers: headers
	// 	})
	// 		.pipe(data => {
	// 			return data;
	// 		});
	// }

	getstatebyCountryId() {

		let httpheader = new HttpHeaders()
		.set('Authorization', 'Basic + this.token');
	  let options = {
		headers: httpheader
	  };
		return this.http.get(`${environment.apiUrl}/state/by-country?countryId=259`)
	}

	getStateByCityId(cityId:number) {

		let httpheader = new HttpHeaders()
		.set('Authorization', 'Basic + this.token');
	  let options = {
		headers: httpheader
	  };
		return this.http.get(`${environment.apiUrl}/state/by-city?cityId=${cityId}`,options)
	}


	getCitybyStateId(stateId: number) {
		let httpHeader = new HttpHeaders()
		  .set('Authorization', 'Basic ' + this.token);
		let options = {
		  headers: httpHeader
		};
		return this.http.get(`${environment.apiUrl}/city/by-state?stateId=${stateId}`, options);
	  }
	  
	  getCitybyCountryId() {
		let httpHeader = new HttpHeaders()
		  .set('Authorization', 'Basic ' + this.token);
		let options = {
		  headers: httpHeader
		};
		return this.http.get(`${environment.apiUrl}/city/by-country?countryId=259`);
	  }

	  getProprtybyUser() {
		let httpHeader = new HttpHeaders()
		  .set('Authorization', 'Basic ' + this.token);
		  console.log(this.token);
		  
		let options = {
		  headers: httpHeader
		};
		return this.http.get(`${environment.apiUrl}/properties/fetch-properties/by-user`,options);
	  }
  
	  getProfileData(){
		this.Token=localStorage.getItem('access_token');
		console.log('Token',this.Token);
		let httpHeader = new HttpHeaders()
		  .set('Authorization', 'Basic ' + this.Token);
		  console.log(this.Token);
		  
		let options = {
		  headers: httpHeader
		};
		return this.http.get(`${environment.apiUrl}/user/profile`,options);
	  }

	  getleadbyUser() {
		let httpHeader = new HttpHeaders()
		  .set('Authorization', 'Basic ' + this.token);
		  console.log(this.token);
		  
		let options = {
		  headers: httpHeader
		};
		return this.http.get(`${environment.apiUrl}/leads/user's/lead/list`,options);
	  }
	  
	  userProfile(access:any) {
		let httpheader = new HttpHeaders()
			.set('Authorization', 'Basic ' + access);
		let options = {
			headers: httpheader
		};
	
		return this.http.get(`${environment.apiUrl}/user/profile`, options);
	}
	

	  CreateProperty(payload:any) {
		let httpHeader = new HttpHeaders()
		  .set('Authorization', 'Basic ' + this.token);
		let options = {
		  headers: httpHeader
		};
		return this.http.post(`${environment.apiUrl}/properties/save`, payload,options);
	  }

	//   /leads/save

	saveLead(payload:any){
		let httpHeader = new HttpHeaders()
		.set('Authorization', 'Basic ' + this.token);
	  let options = {
		headers: httpHeader
	  };
	  return this.http.post(`${environment.apiUrl}/leads/save`, payload,options);
	}

	  Updateroperty(payload: any, id: any) {
		console.log(id);
		
		let httpHeader = new HttpHeaders()
		  .set('Authorization', 'Basic ' + this.token);
		let options = {
		  headers: httpHeader
		};
		return this.http.put(`${environment.apiUrl}/properties/update`, payload, {
		  headers: httpHeader,
		  params: { id: id }
		});
	  }

	//     UpdateLead(payload,Id){
			
	// 	let httpHeader = new HttpHeaders()
	// 	  .set('Authorization', 'Basic ' + this.token);
	// 	let options = {
	// 	  headers: httpHeader
	// 	};
	// 	return this.http.get(`${environment.apiUrl}/leads/by-id?id=${Id}`,payload, options);
	//   }
	  UpdateLead(payload: any, Id: string) {
		const httpHeader = new HttpHeaders()
		  .set('Authorization', 'Basic ' + this.token)
		//   .set('Content-Type', 'application/json'); // Setting content type for JSON payload
	
		let options = {
		  headers: httpHeader
		};
	
		return this.http.put(`${environment.apiUrl}/leads/by-id?id=${Id}`, payload, options);
	  }
	  Updatestatus(Id: number, updatedStatus: any ) {
		const httpHeader = new HttpHeaders()
		  .set('Authorization', 'Basic ' + this.token)
		let options = {
		  headers: httpHeader
		};
		return this.http.put(`${environment.apiUrl}/leads/update/status?status=${updatedStatus}&id=${Id}`, options);
	  }

	  BreakdownAmount(expertiseId: number, eProfessionId: number,amount :number ) {
		const httpHeader = new HttpHeaders()
		  .set('Authorization', 'Basic ' + this.token)
		let options = {
		  headers: httpHeader
		};
		return this.http.get(`${environment.apiUrl}/payment_breakdown/amount/breakdown?expertiseId=${expertiseId}&eProfessionId=${eProfessionId}&amount=${amount}`, options);

	  }
	  
	  
	//   /properties/by-id?id=26

	getPropertyById(propertyId){
        let httpHeader = new HttpHeaders()
		  .set('Authorization', 'Basic ' + this.token);
		let options = {
		  headers: httpHeader
		};
		return this.http.get(`${environment.apiUrl}/properties/by-id?id=${propertyId}`, options);
	  }
	//   /profession/fetch-all

	getLeadById(Id){
        let httpHeader = new HttpHeaders()
		  .set('Authorization', 'Basic ' + this.token);
		let options = {
		  headers: httpHeader
		};
		return this.http.get(`${environment.apiUrl}/leads/by-id?id=${Id}`, options);
	  }

	  getLeadwithoutUser(Id){
        let httpHeader = new HttpHeaders()
		  .set('Authorization', 'Basic ' + this.token);
		let options = {
		  headers: httpHeader
		};
		return this.http.get(`${environment.apiUrl}/leads/by-id/without-user?id=${Id}`, options);
	  }
	fetchAllProfession(){
		let httpHeader = new HttpHeaders()
		  .set('Authorization', 'Basic ' + this.token);
		let options = {
		  headers: httpHeader
		};
		return this.http.get(`${environment.apiUrl}/profession/fetch-all`,options);
	}
	// leads/by-id/without-user?id

	// expertise-list/by-profession
	getExpertiseByProfession() {
		let httpHeader = new HttpHeaders()
			.set('Authorization', 'Basic ' + this.token);
		let options = {
			headers: httpHeader
		};
		return this.http.get(`${environment.apiUrl}/profession/fetch-all`, options);
	}
	getProfession() {
		let httpHeader = new HttpHeaders()
			.set('Authorization', 'Basic ' + this.token);
		let options = {
			headers: httpHeader
		};
		return this.http.get(`${environment.apiUrl}/profession/fetch-all`, options);
	}
	getExperties(Id){
		let httpHeader = new HttpHeaders()
		  .set('Authorization', 'Basic ' + this.token);
		let options = {
		  headers: httpHeader
		};
		return this.http.get(`${environment.apiUrl}/expertise/fetch-by-profession?id=${Id}`,options);
	}

	getExpertiseByProfession1(searchValue:any) {
		let httpHeader = new HttpHeaders()
			.set('Authorization', 'Basic ' + this.token);
		let options = {
			headers: httpHeader
		};
		return this.http.get(`${environment.apiUrl}/expertise/expertise-list/by-profession?expertiseName=${searchValue}`, options);
	}
	getExpertise(searchValue:any) {
		let httpHeader = new HttpHeaders()
			.set('Authorization', 'Basic ' + this.token);
		let options = {
			headers: httpHeader
		};
		return this.http.get(`${environment.apiUrl}/expertise/fetch-all?name=${searchValue}`, options);
	}
	getProfesion(searchValue:any) {
		let httpHeader = new HttpHeaders()
			.set('Authorization', 'Basic ' + this.token);
		let options = {
			headers: httpHeader
		};
		return this.http.get(`${environment.apiUrl}/profession/fetch-all?name=${searchValue}`, options);
	}
	fetchAllExperties(){
		let httpHeader = new HttpHeaders()
		  .set('Authorization', 'Basic ' + this.token);
		let options = {
		  headers: httpHeader
		};
		return this.http.get(`${environment.apiUrl}/expertise/fetch-all`,options);
	}
	fetchidExperties(Id){
		let httpHeader = new HttpHeaders()
		  .set('Authorization', 'Basic ' + this.token);
		let options = {
		  headers: httpHeader
		};
		return this.http.get(`${environment.apiUrl}/expertise/fetch-by-profession?id=${Id}`,options);
	}
	property(pdata) {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Basic ' + this.token
		});
		return this.http.post(`${environment.apiChatUrl}/properties/fetch/properties`, JSON.stringify(pdata), {
			headers: headers
		})
			.pipe(data => {
				return data;
			});
	}
	getprofession_details(name:any){
        let httpHeader = new HttpHeaders()
		  .set('Authorization', 'Basic ');
		let options = {
		  headers: httpHeader
		};
		return this.http.get(`${environment.apiUrl}/profession_details/fetch/by-project-name?name=${name}`, options);
	  }
	Lead(pdata) {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Basic ' + this.token
		});
		return this.http.post(`${environment.apiChatUrl}/leads/paginated-leads?userType=User`, JSON.stringify(pdata), {
			headers: headers
		})
			.pipe(data => {
				return data;
			});
	}

	getMediaData(purpose: string, contentType: string, file: any) {
		const httpOptions = {
		  headers: new HttpHeaders({
			Authorization: 'Basic ' + this.token,
		  }),
		};
	
		return this.http
		  .get(
			`${environment.apiUrl}/media/presigned?purpose=${purpose}&contentType=${contentType}`,
			httpOptions
		  )
		  .pipe(
			concatMap((res: any) =>
			  this.s3Upload(
				res['payload']['preSignedUrl'],
				res['payload']['key'],
				file,
				contentType
			  )
			),
			map((response: any) => {
			  return { url: response.url, type: contentType };
			})
		  );
	  }
	
	
	  s3Upload(
		preSignedUrl: string,
		key: string,
		file: any,
		contentType: string
	  ) {
		// this.key = key;
		// const formData = new FormData();
		// formData.append("file", file)
		const headersPic = new HttpHeaders({
		  'Content-Type': contentType,
		});
	
		let httpheader = new HttpHeaders().set('Content-Type', contentType);
		let options = {
		  headers: httpheader,
		};

		return this.http.put(preSignedUrl, file, options).pipe(
		  map((response: any) => {
			return { url: key, type: contentType };
		  })
		);
	  }	


	  CreateComment(leadId: string, commentData: any) {
		const httpHeader = new HttpHeaders()
		  .set('Authorization', `Basic ${this.token}`);
		
		const options = {
		  headers: httpHeader
		};
	  
		return this.http.post(`${environment.apiUrl}/leads/add/comments?leadId=${leadId}`, commentData, options);
	  }
	  

	  showComment(id: any){
		let httpHeader = new HttpHeaders()
		  .set('Authorization', 'Basic ' + this.token);
		let options = {
		  headers: httpHeader
		};
		return this.http.get(`${environment.apiUrl}/leads/fetch-comments?id=${id}`,options);
	}
	sendotpToMobile(data:any){
		const countryCode = '+91';
		let httpHeader = new HttpHeaders()
		  .set('Authorization', 'Basic ' + this.token);
		let options = {
		  headers: httpHeader
		};
		return this.http.get<any>(`${environment.apiUrl}/leads/send-otp/for-lead?mobileNumber=${data}&mobileCode=${encodeURIComponent(countryCode)}`, options);
	
	  } 

	 
	  AddNewLead(mobileNumber,professionId,expertiseId,otp){
		const countryCode = '+91';
		let httpHeader = new HttpHeaders()
		  .set('Authorization', 'Basic ' + this.token);
		let options = {
		  headers: httpHeader
		};
		return this.http.post<any>(`${environment.apiUrl}/leads/add/new/lead?mobileNumber=${mobileNumber}&countryCode=${encodeURIComponent(countryCode)}&professionId=${professionId}&expertiseId=${expertiseId}&otp=${otp}`, options)
	  } 

}