import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
// import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmbedVideo } from 'ngx-embed-video';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import {MDBBootstrapModule } from 'angular-bootstrap-md';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import {NgxPaginationModule} from 'ngx-pagination';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ContractDetailComponent } from './contract-detail/contract-detail.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { UpdateAssignmentComponent } from './update-assignments/update-assignments.component';
import { SearchAssignmentComponent } from './search-assignments/search-assignments.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SearchArtistComponent } from './search-artist/search-artist.component';

import { ResetpasswordComponent } from './resetpassword/resetpassword.component';

import { ProfileComponent } from './profile/profile.component';
import { AgGridModule } from 'ag-grid-angular';
import { ImageCropperModule } from 'ngx-image-cropper';  

import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

import { ProfileUpdateComponent } from './profileupdate/profileupdate.component';


import { WorkGridButtonCellComponent } from './work-grid-btn-cell/work-grid-btn-cell.component';



import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule } from '@angular/material/dialog';

import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import { QrCodeModule } from 'ng-qrcode';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCardModule} from '@angular/material/card';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { MapComponent } from './map/map.component';
import { MatInputModule } from '@angular/material/input';

import { ArgmapComponent } from './argmap/argmap.component';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {MatSelectModule} from '@angular/material/select';
import { EmailverifyComponent } from './verifymodel/emailverify/emailverify.component';
import { NgOtpInputModule } from 'ng-otp-input';

import { PropertyCardComponent } from './property-card/property-card.component';
import { AddPropertyComponent } from './add-property/add-property.component';
// import { OwlModule } from 'ngx-owl-carousel-o';
import {MatStepperModule} from '@angular/material/stepper';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

import { MatSliderModule } from '@angular/material/slider';
import { PropertySlideComponent } from './property-slide/property-slide.component';
import { PropertyDetailsComponent } from './property-detail/property-details.component';
import { MatDividerModule } from '@angular/material/divider';
import { PropertyOwnerComponent } from './property-owner/property-owner.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TimeAgoPipe } from './time-ago.pipe';
import { NotFoundComponent } from './not-found/not-found.component';
import { MapSelectorComponent } from './google-map/map-selector.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MaskMobileEmailPipe } from './mask-mobile-email.pipe';
import { ImageSlideComponent } from './image-slider/image-slide.component';
import { CroreFormatPipe } from './crore-format.pipe';
import { CapitalizePipe } from './pipe/capitalize.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserPropertyComponent } from './user-property/user-property.component';
import { AddLeadComponent } from './add-lead/add-lead.component';
import { MatNativeDateModule } from '@angular/material/core';
import { LeadDetailsComponent } from './Lead-details/lead-details.component';
import { UserLeadsComponent } from './my-lead/user-leads.component';
import { StripHtmlPipe } from './pipe/StripHtml.Pipe ';
import { SafeHtmlPipe } from './pipe/SafeHtml.pipe';
import { MatTabsModule } from '@angular/material/tabs';
import { CommentComponent } from './Comment/comment.component';
import { AmountDialogComponent } from './Amount-dialog/amount-dialog.component';
import { AboutUsComponent } from './Footer-content/about-us/about-us.component';
import { FaqComponent } from './Footer-content/FAQ/faq.component';
import { PrivacyPolicyComponent } from './Footer-content/privacy-policy/privacy-policy.component';
import { TermAndConditionComponent } from './Footer-content/term-and-condition/term-and-condition.component';
import { VerifieduserComponent } from './verifieduser/verifieduser.component';
import { VerifiedotpComponent } from './verifiedotp/verifiedotp.component';
import { ExpensionCardComponent } from './expension-card/expension-card.component';
import { ExpertiesPopupComponent } from './experties-popup/experties-popup.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    HomeComponent,
    StripHtmlPipe ,
    SafeHtmlPipe ,
    ForgotpasswordComponent,
  
   
    ContractDetailComponent,
    UpdateAssignmentComponent,
    SearchAssignmentComponent,
    SearchArtistComponent,
  
    ResetpasswordComponent,
    ProfileComponent,
  
    
    ProfileUpdateComponent,
 
    WorkGridButtonCellComponent,
   
    ForgotpasswordComponent,
 
   
    MapComponent,
   
    ArgmapComponent,
    EmailverifyComponent,
    PropertyCardComponent,
    AddPropertyComponent,
    PropertySlideComponent,
    PropertyDetailsComponent,
    PropertyOwnerComponent,
    TimeAgoPipe,
    NotFoundComponent,
    MapSelectorComponent,
    MaskMobileEmailPipe,
    ImageSlideComponent,
    CroreFormatPipe,
    CapitalizePipe,
    UserPropertyComponent,
    AddLeadComponent,
    LeadDetailsComponent,
    UserLeadsComponent,
    CommentComponent,
    AmountDialogComponent,
    AboutUsComponent,
    FaqComponent,
    PrivacyPolicyComponent,
    TermAndConditionComponent,
    VerifieduserComponent,
    VerifiedotpComponent,
    ExpensionCardComponent,
    ExpertiesPopupComponent,
    
   
    

  ],
  imports: [
    BrowserModule,MatTabsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatSidenavModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatDividerModule,
    MatDialogModule ,
    MatStepperModule,   
    MatExpansionModule,
    MatChipsModule,
    GoogleMapsModule,
    MatTooltipModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyDFaXNvUSNlqQoqlNBgCgppWcSeYxb5kDM'
    // }),

    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    BrowserModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    FormsModule,
    Ng2SearchPipeModule,
    HttpClientModule,
    SocialLoginModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxPaginationModule,
    // SlickCarouselModule,
    EmbedVideo.forRoot(),
    ImageCropperModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
    PickerModule,
    NgbModule,
    EditorModule,
    MDBBootstrapModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    BrowserAnimationsModule,
    MatAutocompleteModule,
    AngularMultiSelectModule,
    MatIconModule,
    MatMenuModule,
    QrCodeModule,
    MatProgressBarModule,
    MatCardModule,
    AgGridModule.withComponents([]),
    MatInputModule,
    LeafletModule,MatSelectModule,MatInputModule ,
    NgOtpInputModule,MatGridListModule,MatRadioModule,MatCheckboxModule,

    LeafletModule,MatSelectModule,MatInputModule,
   
    
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '1081048444803-etqr7jr3f25ct2ib0t751vj6desfegv6.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('247313394079751')
          }
        ]
      } as SocialAuthServiceConfig,
    },
    BsModalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
