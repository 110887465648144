<div style="height: 31pc;">
<div class="row p-2" style="max-height:500pc ;">
  <div class="col-md-6">
    <div class="avatar-banner">
      <img alt="" src="{{profilePic}}"
        style="height:15pc ; max-height:477px;border-radius: 50%;border: 5px solid;padding: 8px;border-color: #007bff; margin-left: auto; margin-right:auto; margin-top: 125px;">
    </div>
  </div>
  <div class="col-md-6">
    <div class="contact-form" style="margin-top: 85px;">
      <h3>User Contact Details</h3>
      <p>(This is where people interested in your property will contact you)</p>
      
      <form [formGroup]="updateform" (ngSubmit)="onSubmit()">
        <h2 class="createaccount">User Profile</h2>
    
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Full Name" formControlName="fullName"  readonly/>
        
        </div>
    
        <div class="form-group">
          <input type="email" class="form-control" placeholder="Email" formControlName="email" readonly />
         
        </div>
    
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Mobile Number" formControlName="mobileNumber" readonly/>
          
        </div>
      </form>
    </div>
  </div>
</div>
</div>