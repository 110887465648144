<div class="row" style="padding: 0px !important;">
    <div class="col-12 text-right">
        <button type="button" class="close" aria-label="Close" (click)="closeModal()" 
        style="border: none; background: none; padding: 0; outline: none; box-shadow: none;">
  &times;
</button>

    </div>
</div>
  
<table style="width: 100%; border-collapse: collapse; font-family: Arial, sans-serif;">
    <thead>
      <tr style="background-color: #f2f2f2;">
        <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Detail</th>
        <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Percentage</th>
        <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Value</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style="border: 1px solid #dddddd; padding: 8px;"><strong>Expert Fees</strong></td>
        <td style="border: 1px solid #dddddd; padding: 8px;">{{ payload.expertFeesPercentage }}%</td>
        <td style="border: 1px solid #dddddd; padding: 8px;">{{ payload.expertFees }}</td>
      </tr>
      <tr>
        <td style="border: 1px solid #dddddd; padding: 8px;"><strong>Ground Support Team</strong></td>
        <td style="border: 1px solid #dddddd; padding: 8px;">{{ payload.groundSupportTeamPercentage }}%</td>
        <td style="border: 1px solid #dddddd; padding: 8px;">{{ payload.groundSupportTeam }}</td>

      </tr>
      <tr>
        <td style="border: 1px solid #dddddd; padding: 8px;"><strong>IT Support Team</strong></td>
        <td style="border: 1px solid #dddddd; padding: 8px;">{{ payload.itSupportTeamPercentage }}%</td>
        <td style="border: 1px solid #dddddd; padding: 8px;">{{ payload.itSupportTeam }}</td>

      </tr>
      <tr>
        <td style="border: 1px solid #dddddd; padding: 8px;"><strong>Sales Team</strong></td>
        <td style="border: 1px solid #dddddd; padding: 8px;">{{ payload.salesTteamPercentage }}%</td>
        <td style="border: 1px solid #dddddd; padding: 8px;">{{ payload.salesTteam }}</td>
      </tr>
      <tr>
        <td style="border: 1px solid #dddddd; padding: 8px;"><strong>Profit Percentage</strong></td>
        <td style="border: 1px solid #dddddd; padding: 8px;">{{ payload.profitPercentagePercentage }}%</td>
        <td style="border: 1px solid #dddddd; padding: 8px;">{{ payload.profitPercentage }}</td>

      </tr>
      <tr>
        <td style="border: 1px solid #dddddd; padding: 8px;"><strong>Platform Fees</strong></td>
        <td style="border: 1px solid #dddddd; padding: 8px;">{{ payload.plateformFeesPercentage }}%</td>
        <td style="border: 1px solid #dddddd; padding: 8px;">{{ payload.plateformFees }}</td>

      </tr>
      <tr>
        <td style="border: 1px solid #dddddd; padding: 8px;"><strong>Infra Cost</strong></td>
        <td style="border: 1px solid #dddddd; padding: 8px;">{{ payload.infraCostPercentage }}%</td>
        <td style="border: 1px solid #dddddd; padding: 8px;">{{ payload.infraCost }}</td>

      </tr>
    </tbody>
  </table>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" data-bs-dismiss="modal"  (click)="handleOk()" style="background: #004aad;">OK</button>
  </div>
  
  