import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { ArgmapComponent } from './argmap/argmap.component';


import { ContractDetailComponent } from './contract-detail/contract-detail.component';

import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { MapComponent } from './map/map.component';


import { ProfileComponent } from './profile/profile.component';
import { ProfileUpdateComponent } from './profileupdate/profileupdate.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { SearchArtistComponent } from './search-artist/search-artist.component';
import { SearchAssignmentComponent } from './search-assignments/search-assignments.component';

import { UpdateAssignmentComponent } from './update-assignments/update-assignments.component';

import { AuthGuard } from './_helpers/auth.guard';
import { AddPropertyComponent } from './add-property/add-property.component';
import { PropertyDetailsComponent } from './property-detail/property-details.component';
import { PropertyOwnerComponent } from './property-owner/property-owner.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ImageSlideComponent } from './image-slider/image-slide.component';
import { UserPropertyComponent } from './user-property/user-property.component';
import { AddLeadComponent } from './add-lead/add-lead.component';
import { LeadDetailsComponent } from './Lead-details/lead-details.component';
import { UserLeadsComponent } from './my-lead/user-leads.component';
import { PrivacyPolicyComponent } from './Footer-content/privacy-policy/privacy-policy.component';
import { TermAndConditionComponent } from './Footer-content/term-and-condition/term-and-condition.component';
import { AboutUsComponent } from './Footer-content/about-us/about-us.component';
import { FaqComponent } from './Footer-content/FAQ/faq.component';
import { ExpensionCardComponent } from './expension-card/expension-card.component';
import { ExpertiesPopupComponent } from './experties-popup/experties-popup.component';
const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'add-lead', component: AddLeadComponent },

  { path: 'image-slider', component: ImageSlideComponent },
  { path: 'home/:s', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'update-assignment/:assignmentId', component: UpdateAssignmentComponent, canActivate: [AuthGuard] },
  { path: 'search-assignments/:se', component: SearchAssignmentComponent },
  { path: 'search-artist/:se', component: SearchArtistComponent },
  { path: 'contract-detail/:contractId', component: ContractDetailComponent, canActivate: [AuthGuard] },
  { path: 'resetpassword', component: ResetpasswordComponent},
  { path: 'profile/:username/:userid/:activetab', component: ProfileComponent},
  { path: 'update/profile/username/userid/activetab', component: ProfileUpdateComponent },
  { path: 'setnewpassword/:email', component: ForgotpasswordComponent },
  { path: 'map', component: MapComponent},
  { path: 'artistmap', component: ArgmapComponent},
  { path: 'add-property', component: AddPropertyComponent },
  { path: 'property-details', component: PropertyDetailsComponent },
  { path: 'party-details', component:PropertyOwnerComponent },
  { path: 'create/id', component: AddPropertyComponent },
  { path: 'UserProperty', component: UserPropertyComponent },
  { path: 'Leads-deatils', component: LeadDetailsComponent },
  { path: 'expertiescard',component:ExpertiesPopupComponent},
  { path: 'UserLeads', component: UserLeadsComponent },

  { path: 'privacyPolicy', component:PrivacyPolicyComponent },
  { path: 'termsandcondition', component: TermAndConditionComponent },
  { path: 'aboutUs', component: AboutUsComponent },
  // { path: 'contactUs', component: ContactUsComponent },
  { path: 'FAQ', component: FaqComponent },
  { path: 'card', component: ExpensionCardComponent },

  { path: 'notfound', component: NotFoundComponent },
  {path: '**', redirectTo: '/404'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],

  exports: [RouterModule]
})


export class AppRoutingModule { }
