<footer class="footer-classic footer">
  <div class="container">
    <div class="row row-20">
      <!-- <div class="col-lg-4 col-md-6 col-sm-6 mt-3">
        <h5>SOCIAL MEDIA</h5>
        <ul class="nav-list" style="text-decoration: none;">
          <li><a href="#" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i>&nbsp;&nbsp;Facebook</a></li>
          <li><a href="#" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i>&nbsp;&nbsp;Instagram</a></li>
          <li><a href="#" target="_blank"><i class="fa fa-twitter-square" aria-hidden="true"></i>&nbsp;&nbsp;Twitter</a></li>
        </ul>
      </div> -->
      <div class="col-lg-4 col-md-6 col-sm-6 mt-3">
        <h5>LEGAL CONTENT</h5>
        <ul class="nav-list">
          <li><a routerLink="/termsandcondition"><i class="fa fa-files-o" aria-hidden="true"></i>&nbsp;&nbsp;Term and Conditions</a></li>
          <li><a routerLink="/privacyPolicy"><i class="fa fa-user-secret" aria-hidden="true"></i>&nbsp;&nbsp;Privacy Policy</a></li>
        </ul>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 mt-3">
        <h5 (click)="navigateTOcontactUs()" style="cursor: pointer;">CONTACT US</h5>
        <ul class="nav-list">
          <li><a href="mailto:info@kdproperty.co.in"><i class="fa fa-envelope" aria-hidden="true"></i>&nbsp;&nbsp;info@theswap.in</a></li>
        </ul>
      </div>
      <!-- <div class="col-lg-4 col-md-6 col-sm-6 mt-3">
        <h5>BLOGS</h5>
        <ul class="nav-list">
          <li><a routerLink="#"><i class="fa fa-users" aria-hidden="true"></i>&nbsp;&nbsp;Blogs</a></li>
        </ul>
      </div> -->
      <div class="col-lg-4 col-md-6 col-sm-6 mt-3">
      <h5>FAQ</h5>
        <ul class="nav-list">
          <li><a routerLink="FAQ"><i class="fa fa-users" aria-hidden="true"></i>&nbsp;&nbsp;FAQ</a></li>
        </ul>
      </div> 
      <div class="col-lg-4 col-md-6 col-sm-6 mt-3">
        <h5>ABOUT US</h5>
        <ul class="nav-list">
          <li><a routerLink="aboutUs"><i class="fa fa-users" aria-hidden="true"></i>&nbsp;&nbsp;About us</a></li>
        </ul>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 mt-3">
        <ul class="nav-list">
          <li>©2035 theswap.in Team. All Rights Reserved.</li>
        </ul>
      </div>
    </div>
  </div>
</footer>
